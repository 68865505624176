
/* Basic CSS starts */

body {
    padding-top: 80px;
    color: #656565;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    color: #434343;
}

p {
    margin:0;
    padding:0;
    font-size: 14px;
    line-height: 24px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:hover,a:active,a:focus {
    outline: 0;
    outline-width: 0 !important;
}

hr {

}

:focus {
    outline:none;
    outline-width: 0 !important;
}

::-moz-focus-inner {
    border:0;
}
form label {
    font-size: 13px;
    font-weight: normal;
    color: #9a9a9a;
}

form input, form input:focus,
form textarea, form textarea:focus {
    border: 1px solid #ddd !important;
    border-radius: 2px;
    box-shadow: none !important;
    font-size: 13px !important;
    color: #9a9a9a;
}f

/* Social CSS */

.social a {
   display:inline-block;
   width:40px;
   height:40px;
   font-size:15px;
   line-height:40px;
   text-decoration:none;
   text-align:center;
   -webkit-transition: all 1s ease;
   -moz-transition: all 1s ease;
   -o-transition: all 1s ease;
   -ms-transition: all 1s ease;
   transition: all 1s ease;
}

.social a, .social a:hover {
   border:0px;
   text-decoration:none;
}

a.btn {
    border-radius: 2px;
    box-shadow: 0 2px 0 0 rgba(255,255,255,0.05) inset,0 2px 3px rgba(0,0,0,0.05);
}

.btn-transparent {
    color: #fff;
    font-size: 16px;
    background: transparent;
    border: 2px solid #fff !important;
    box-shadow: 0 1px 6px rgba(0,0,0,0.3),inset 0 0 0 1px #fff;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.btn-transparent:hover {
    color: #474747;
    box-shadow: inset 0 -60px 0 1px #fff;
}

.overlay-pattern {background: url('../img/overlay-pattern.png') repeat;}
.parallax-one {background: url('../img/overlay-pattern.png'), url('../img/background/1.jpg') 50% fixed;}
.parallax-two {background: url('../img/overlay-pattern.png'), url('../img/background/2.jpg') 50% fixed;}

/* Basic CSS ends */

/* style switcher */

.style-switcher {
    width:30px;
    padding:3px;
    position:fixed;
  top: 150px;
  left: -31px;
    z-index: 500;
    background:#fff;
    box-shadow:0px 0px 5px rgba(0,0,0,0.3);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.style-switcher a {
    display:block;
    width:18px;
    height:18px;
    margin:3px;
}

.style-switcher .style-btn {
    position: absolute;
    top: 15px;
    left: 100%;
    margin-left: 0;
    z-index: 501;
    width: 30px;
    height: 30px;
    line-height: 29px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    color: #565656;
    border: 1px solid #ccc;
    border-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

/* tool tip */

.tooltip .tooltip-inner {border-radius: 2px !important;}

/* Default Padding */

.pad {padding: 120px 0px;}

/* Container Default Heading */

.container .default-header {
    text-align: center;
    margin-bottom: 80px;
}

.container .default-header  i {
    display: block;
    margin: 0 auto;
    font-size: 48px;
}

.container .default-header h3 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 50px;
}

.container .default-header p {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.036em;
}


/* Header */

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 510;
    width: 100%;
    background: #fefefe;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 1px 10px #ddd;
}

.header-info {
    background: #fafafa;
    border-bottom: 1px solid #ddd;
}

.header-info .input-group .form-control {
    height: 42px;
    font-size: 16px !important;
    border-radius: 0;
    border-bottom: 0 !important;
    border-top: 0 !important;
    box-shadow: none;
}

.header-info .input-group .btn {
    padding: 0 14px;
    background: #f5f5f5;
    border-color: #ddd;
    border-radius: 0;
    border-bottom: 0;
    border-top: 0;
}
.header-info .input-group .btn i {
    font-size: 20px;
    line-height: 42px;
    color: #aaa;
}

.header-info .social {border-right: 1px solid #ddd;}
.header-info .social a {
    float: right;
    margin: 0;
    width: 44px;
    height: 42px;
    line-height: 41px;
    font-size: 18px;
    border: none;
    border-left: 1px solid #ddd;
}
.header-info .social a:hover {background: #fff;}
.header-info .social a i {
    color: #aaa;
}

/* Navigation Menu */

.navbar-default {
    margin-bottom: 0;
    background: transparent;
    border-radius: 0px;
    border: 0px;
}
.navbar-header .navbar-brand {padding-top: 2px;}

.navbar-header .navbar-brand img {
    width: 100%;
    max-width: 300px;
    padding: 5px 5px 0;
}
.navbar-default .navbar-nav {border-right: 1px solid #ddd;}
.navbar-default .navbar-nav > li > a {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 10px;
    font-weight: 700;
    color: #898989;
    border-left: 1px solid #ddd;
    text-transform: uppercase;
}

@include small-tablet {
    .navbar-default .navbar-nav > li > a {
        font-size: 14px;
    }
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav>.dropdown>a:focus,
.navbar-default .nav .open>a, .navbar-default .nav .open>a:hover, .navbar-default .nav .open>a:focus {
    background: transparent;
    border-color: #ddd;
}

.navbar-default .nav .dropdown-menu {
    left: 0;
    min-width: 180px;
    padding-top: 3px;
    padding-bottom: 0;
    border: 0;
    border-radius: 0px;
}

.navbar-default .nav .dropdown-menu > li > a {
    padding: 10px 12px;
    font-size: 14px;
    background: #fff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.navbar-default .navbar-toggle {
    top: 0px;
    border-radius: 2px;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background: transparent;
}

.navbar-default > .container {
    width: 100%;
    padding: 0;
}
/* compressed header */
.header-compress .navbar-header .navbar-brand img {max-width: 110px;}
.header-compress .navbar-default .navbar-nav > li > a {padding-top: 20px;padding-bottom: 20px;}

/* carousel banner */

.banner .carousel .carousel-control {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    display: inline-block;
    font-size: 18px;
    text-align: center;
    background: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
    border:0;
    opacity: 1;
    text-shadow: none;
}

.banner .carousel .carousel-control span {
    width: 50px;
    height: 50px;
    line-height: 52px;
    font-size: 22px;
    background: #f8f8f8;
    border-radius: 100px;
    box-shadow: 0 0 0 5px rgba(255,255,255,0.6), inset 0 0 0 1px #ddd;
}

.banner .slide-one {
    bottom: 150px;
    text-align: center;
}
.banner .slide-one h2 {
    margin-bottom: 20px;
    font-size: 72px;
    color: #fff;
}

.banner .slide-one p {
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.banner .slide-one .btn {
    display: inline-block;
    margin-top: 30px;
    padding: 12px 32px;
    font-size: 16px;
}
.banner .slide-two {bottom: 80px;}
.banner .slide-two h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 56px;
    color: #fff;
}
.banner .slide-two p {
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
.banner .slide-two .showcase {
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    margin-top: 20px;
}

/* feature */

.feature .feature-item {
    margin-bottom: 30px;
    text-align: center;
}
/*
.feature .feature-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation-duration: 1.25s;
    -moz-animation-duration: 1.25s;
    -ms-animation-duration: 1.25s;
    -o-animation-duration: 1.25s;
    animation-duration: 1.25s;
}

.delay-one {
    -webkit-animation-delay: 0s;
    -moz-animation-delay:0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}

.delay-two {
    -webkit-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -ms-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

.delay-three {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.delay-four {
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
}

.feature .feature-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.feature .feature-item a {
    display: block;
    width: 100%;
    text-align: center;
}

.feature .feature-item a i {
    display: inline-block;
    width: 110px;
    height: 110px;
    line-height: 102px;
    font-size: 40px;
    color: #ddd;
    border: 5px solid #ddd;
    border-radius: 150px;
    box-shadow: inset 0 0 1px 1px #fff, 0 0 1px 4px #fff;
    -webkit-transition: all 0.35s linear;
    -moz-transition: all 0.35s linear;
    -ms-transition: all 0.35s linear;
    -o-transition: all 0.35s linear;
    transition: all 0.35s linear;
}

.feature .feature-item:hover a i {
    border-color: #fff;
}

.feature .feature-item h4 {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 28px;
}

.feature .feature-item p {
    font-size: 16px;
    line-height: 28px;
}


/* Service */

.service .service-item {
    position: relative;
    top: 0;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #ddd;
    -webkit-transition: all 0.35s linear;
    -moz-transition: all 0.35s linear;
    -ms-transition: all 0.35s linear;
    -o-transition: all 0.35s linear;
    transition: all 0.35s linear;
}
.service .service-item:hover {top: -10px;}
/*
.service .service-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation-duration: 1.25s;
    -moz-animation-duration: 1.25s;
    -ms-animation-duration: 1.25s;
    -o-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-delay: 0s;
    -moz-animation-delay:0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}

.service .service-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.service .service-item a i {
    display: inline-block;
    margin-right: 15px;
    width: 35px;
    text-align: center;
    font-size: 32px;
    line-height: 32px;
    color: #ddd;
}

.service .service-item a span.service-title {
    display: inline-block;
    font-size: 28px;
    line-height: 32px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
}
.service .service-item p {margin-top: 15px;}

/* Portfolio */

.portfolio {padding-top: 100px;}
.portfolio .container .default-header {margin-bottom: 40px;}
.portfolio #filters {   margin-bottom:50px;}

/**** Filter ****/

#filters {
    margin-bottom: 30px;
    text-align: center;
}

#filters li { display:inline; }
#filters li a.btn {
    margin: 3px 2px;
}

/**** Isotope Filtering ****/

.isotope-item { z-index: 2; }
.isotope-hidden.isotope-item {
    z-index: 1;
}

/**** Isotope CSS3 transitions ****/

.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -ms-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -ms-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property:    -moz-transform, opacity;
    -ms-transition-property:     -ms-transform, opacity;
    -o-transition-property:      -o-transform, opacity;
    transition-property:         transform, opacity;
}

.portfolio .p-element {
    width: 24.98%;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    float: left;
}

.portfolio .p-element .element { position: relative; }
.portfolio .p-element .element img {
   -webkit-transition: all 250ms linear;
      -moz-transition: all 250ms linear;
       -ms-transition: all 250ms linear;
                -o-transition: all 250ms linear;
           transition: all 250ms linear;
}

.portfolio .p-element:hover .element img {
    -webkit-transform: scale(1.4);
         -moz-transform: scale(1.4);
            -ms-transform: scale(1.4);
             -o-transform: scale(1.4);
                    transform: scale(1.4);
}

.portfolio-content .p-hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   -webkit-transition: all 300ms linear;
      -moz-transition: all 300ms linear;
             -ms-transition: all 300ms linear;
                -o-transition: all 300ms linear;
           transition: all 300ms linear;
}

.portfolio-content .p-element:hover .p-hover {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.portfolio-content .p-hover a {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
}

.portfolio-content .p-hover i {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 15;
    margin-top: -20px;
    margin-left: -20px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    border-radius: 2px;
}

.portfolio-content .p-element h3 {
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 15;
    margin: 0;
    font-size:18px;
    color: #fff;
    padding:9px 12px;
    background:rgba(0,0,0,0.4);
}

.portfolio-content .p-element:hover h3 {
    background:transparent;
}

/* Testimonial */

.testimonial {
    background: #f8f8f8;
    border-top: 1px solid #e5e5e5;
    box-shadow: inset 0 0 6px #e1e1e1;
}
.testimonial .carousel .carousel-control {
    position: absolute;
    top: 40px;
    z-index: 100;
    display: inline-block;
    width: 25px;
    font-size: 18px;
    text-align: center;
    background: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
    border:0;
    opacity: 1;
    text-shadow: none;
}

.testimonial .carousel .carousel-control.left {left: 50%; margin-left: -90px;}
.testimonial .carousel .carousel-control.right {right: 50%; margin-right: -90px;}
.testimonial .testimonial-content {text-align: center;}
.testimonial .testimonial-content img {
        width: 100%;
        max-width: 90px;
        margin: 0 auto;
        margin-top: 5px;
        border: 6px solid #fdfdfd;
        border-radius: 100px;
        box-shadow: 0 0 3px 1px #ccc;
}

.testimonial .testimonial-content  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
}

.testimonial .testimonial-content p {
    max-width: 1080px;
    margin: 0 auto;
    font-size: 16px;
    line-height:32px;
}

.testimonial .testimonial-content p:before,
.testimonial .testimonial-content p:after {
    position: relative;
    top: -4px;
    display: inline-block;
    font-size: 20px;
    font-family: 'FontAwesome';
}
.testimonial .testimonial-content p:before {
    margin-right: 7px;
    content: "\f10d"; /* Before Quote */
}
.testimonial .testimonial-content p:after {
    margin-left: 7px;
    content: "\f10e"; /* After Quote */
}

/* CTA */

.cta {padding: 80px 0px; text-align: center;}

.cta .cta-content h4 {
    margin: 0px;
    margin-bottom: 10px;
    font-size: 45px;
    color: #fff;
}

.cta .cta-content p {color: #fff;}
.cta .cta-content .cta-btn {
    margin-top: 25px;
    text-align: center;
}

.cta .cta-content .cta-btn a.btn {
    font-weight: bold;
    padding: 15px 30px;
}

/* pricing block */
/*
.pricing-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
    animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay:0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.pricing-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.pricing .pricing-content .pricing-item {
    text-align: center;
    background: #fefefe;
    box-shadow: inset 0 0 0 1px #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.pricing-content .pricing-item h3 {
    margin: 0;
    padding: 30px 0;
    font-size: 32px;
    background: #eee;
    box-shadow: inset 0 0 0 1px #ddd;
}

.pricing-content .pricing-item h4 {
    margin-top: 25px;
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
}

.pricing-content .pricing-item .sub-text {
    display: block;
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
    font-style: italic;
    border-bottom: 1px dashed #ddd;
}

.pricing-content .pricing-item h4 sub {
    position: relative;
    font-size: 20px;
    left: 3px;
    top: 0px;
}

.pricing-content .pricing-item .pricing-details {
    margin: 15px;
    background: #fff;
    border: 1px solid #fff;
}

.pricing-content .pricing-item .pricing-details ul {margin: 0;}
.pricing-content .pricing-item .pricing-details ul li {
    padding: 9px 12px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    border-bottom: 1px solid #fff;
}

.pricing-content .pricing-item .pricing-details ul li:last-child {border-bottom:0;}
.pricing-content .pricing-item .pricing-details ul li:nth-child(odd){background: #edf5f7;}
.pricing-content .pricing-item .pricing-details ul li:nth-child(even){background: #f3fbfe;}
.pricing-content .pricing-item .pricing-details ul li i {
    margin-right: 6px;
    width: 18px;
    font-size: 18px;
}

.pricing-content .pricing-item .btn-area {
    padding: 20px 15px;
    border-top: 1px dashed #ddd;
}

.pricing-content .pricing-item .btn-area .btn {
    display: block;
    padding: 10px 0;
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;
}

.pricing-content .pricing-item.highlight {
    position: absolute;
    left: 0;
    top: -9px;
    width: 100%;
    box-shadow: inset 0 0 0 1px #ddd, 0 1px 20px #ccc;
}

.pricing-content .pricing-item.highlight h3 {font-size: 40px;color: #fff;box-shadow: none;border-bottom: 1px solid #ddd;}
.pricing-content .pricing-item.highlight h4 {font-size: 32px;}
.pricing-content .pricing-item.highlight .sub-text {font-size: 16px;padding-bottom: 16px;}
.pricing-content .pricing-item.highlight .btn-area .btn {padding: 15px 0;}


/* About Us */

.about .about-content {
    padding-bottom: 50px;
}

.about-content h3 {
    margin-top: 0;
    font-size: 32px;
}

.about-content h4 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.about-content img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 30px;
}
/*
.member-content .member-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay:0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.member-content .member-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.member-content .member-item {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 30px;
    border: 1px solid #ddd;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
}
.member-hover-color {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
     -webkit-transition: all 0.35s ease-out;
            -moz-transition: all 0.35s ease;
             -ms-transition: all 0.35s ease;
                -o-transition: all 0.35s ease;
                     transition: all 0.35s ease-out;
    -webkit-transition-delay: 0.3s;                     /* Safari */
         -moz-transition-delay: 0.3s;
            -ms-transition-delay: 0.3s;
             -o-transition-delay: 0.3s;
                    transition-delay: 0.3s;
}
.member-content .member-item .member-hover {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
     -webkit-transition: all 0.5s ease-out;
            -moz-transition: all 0.5s ease;
             -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                     transition: all 0.5s ease-out;
    -webkit-transition-delay: 0s;                   /* Safari */
         -moz-transition-delay: 0s;
            -ms-transition-delay: 0s;
             -o-transition-delay: 0s;
                    transition-delay: 0s;
}
.member-content .member-item:hover .member-hover-color {top:0;}
.member-content .member-item:hover .member-hover {
    top: 0;
    -webkit-transition-delay: 0.5s;                     /* Safari */
         -moz-transition-delay: 0.5s;
            -ms-transition-delay: 0.5s;
             -o-transition-delay: 0.5s;
                    transition-delay: 0.5s;
}
.member-content .member-item p,
.member-content .member-item h3 a {color: #fff;}
.member-content .member-item h3 {
    margin-top: 15px;
    margin-bottom: 0;
}
.member-content .member-item .deg {
    display: block;
    margin-bottom: 16px;
    color: #fff;
}
.member-content .member-item .social {margin-top: 15px;}
.member-content .member-item .social a {
    width: 35px;
    height: 35px;
    margin-left: 0;
    margin-right: 3px;
    background: #fff;
    font-size: 14px;
    line-height: 33px;
    border-radius: 50px;
}
.about .quote-para {
    max-width: 880px;
    margin: 0 auto;
    padding-top: 50px;
    text-align: center;
}

.about .quote-para p {
    font-size: 18px;
    line-height: 32px;
}

/* Our Counter */

.counter {padding: 80px 0;}
.counter .counter-item {
    max-width: 430px;
    margin: 0px auto;
    margin-bottom: 40px;
    text-align: center;
}

.counter .counter-item h3 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 32px;
    color: #fff;
}

.counter .counter-item i {
    display: block;
    text-align: center;
    margin: 0px auto;
    margin-bottom: 20px;
    font-size: 112px;
    color: #fff;
}

.counter .counter-item span {
    display: block;
    padding: 0;
    margin: 0;
    font-size: 45px;
    color: #fff;
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
}

/* blog area */

.blog {
    background: #f8f8f8;
    border-top: 1px solid #e5e5e5;
    box-shadow: inset 0 0 6px #e1e1e1;
}
.entry {
    margin-bottom: 30px;
    padding-bottom: 20px;
    background: #fff;
    box-shadow: inset 0 0 0 1px #ddd;
    border-radius: 2px;
}
/*
.entry {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay:0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.entry.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.entry .entry-head {
    position: relative;
    overflow: hidden;
}

.entry .entry-head .date {
    position: absolute;
    top: 0;
    left: 30px;
    width: 85px;
    padding: 8px 0;
    text-align: center;
    font-size: 20px;
    color: #fff;
    box-shadow: 3px 3px 0 0px #fff;
}
.entry .entry-head .date strong {
    display: block;
    margin-top: 2px;
    font-size: 34px;
    line-height: 32px;
}

.entry .entry-head .meta {
    position: relative;
    padding: 8px 14px;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
}

.entry .entry-head .meta i {font-size: 14px;}
.entry .entry-head .meta:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    margin-left: -7px;
    pointer-events: none;
    border-color: rgba(75, 170, 211, 0);
    border-width: 14px;
}

.entry .entry-body {padding: 12px 14px 0 14px;}
.entry .entry-body .title {
    margin-top: 0;
    font-size: 22px;
    line-height: 30px;
    text-transform: capitalize;
}


/* Process css */

.process-content .process-item {
    max-width: 430px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 20px;
    background: #f8f8f8;
    overflow: hidden;
    box-shadow:inset 0 0 0 1px #ddd;
    text-align: center;
    /*
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
    */
}
/*
.process-content .process-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.process-content .process-item i {
    display: block;
    width: 130px;
    height: 130px;
    line-height: 130px;
    margin: 0px auto;
    text-align: center;
    font-size: 60px;
    color: #ddd;
    background: #fff;
    border-radius: 200px;
    box-shadow: 0 3px 3px #ddd;
}

.process-content .process-item h4 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 28px;
    text-align: center;
}

/* video */

.video {padding-top: 100px; padding-bottom:60px;}
.video .video-caption  {
    margin-bottom: 30px;
}
.video .video-caption i {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #fff;
    font-size: 32px;
    float: left;
    border-radius: 100px;
}

.video .video-caption h3 {
    margin-top: 0;
    margin-left: 100px;
    margin-bottom: 10px;
    font-size: 28px;
    color: #fff;
}

.video .video-caption p {
    margin-left: 100px;
    font-size: 13px;
    line-height: 23px;
    font-style: Italic;
    color: #fff;
}

/*
.video-caption,
.video .video-container {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 0s;
    -moz-animation-duration: 1s;
    -moz-animation-delay:0s;
    -ms-animation-duration: 1s;
    -ms-animation-delay: 0s;
    -o-animation-duration: 1s;
    -o-animation-delay: 0s;
    animation-duration: 1s;
    animation-delay: 0s;
}

.video-caption.animation,
.video .video-container.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/

/* Contact */

.map-content .gmap {
    width: 100%;
    height: 300px;
    border: 5px solid #ddd;
    border-radius: 3px;
}

.map-content h2 {
    margin-top: 0;
    font-size: 50px;
    line-height: 45px;
    text-transform: uppercase;
}

.map-content p {text-transform: uppercase;}
/*
.form-content {
    margin-top: 40px;
    padding-top: 30px;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
}

.form-content.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.form-content p {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
}

.form-content form {
    margin-top: 30px;
}

.form-content form label {
    padding-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    color: #454545;
}

.form-content form .form-control {border-radius: 1px;}
.form-content form .btn {
    display: inline-block;
    margin-top: 30px;
    padding: 9px 20px;
    font-size: 15px;
    border-radius: 1px;
}

#success,
#error {
    margin-top: 30px;
  display:none;
}

/* login page sign in, sign up */

.sign-area {
    max-width: 450px;
    margin: 0px auto;
    margin-top: 50px;
    margin-bottom: 150px;
    background: #fafafa;
    box-shadow: 0 7px 15px #ddd;
}

.sign-area .nav-tabs.nav-justified>li>a {
    padding: 16px 0;
    font-size: 16px;
    line-height: 20px;
    background: #fff;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
}
.sign-area .nav-tabs.nav-justified>li>a i {font-size: 18px;line-height:20px;}

.sign-area .tab-content {
    padding: 30px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.tab-content .form-area h3 {
    margin-top: 0;
    padding-bottom: 14px;
    text-align: center;
    font-size: 28px;
    border-bottom: 1px solid #ddd;
}

.tab-content form label {
    color: #434343;
    font-weight: 700;
}

.tab-content form .btn {
    padding: 7px 16px;
    font-size: 14px;
}

/* Footer */

.footer {
    padding-top: 60px;
    padding-bottom: 30px;
}
.footer a {color: #fff;}
.footer-content {margin-bottom: 30px;}
.footer-content .footer-widget {margin-bottom: 30px;}
.footer-content .footer-widget h4 {
    margin-top: 0;
    padding-bottom: 12px;
    font-size: 24px;
    color: #fff;
}

.footer-content .footer-widget p {color: #fff;}
.footer-widget .footer-post li {
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #fff;
}

.footer-widget .footer-post li:last-child {border-bottom: 0;padding-bottom:0;}

.footer-widget .footer-post img {
    max-width: 70px;
    border: 3px solid #fff;
    border-radius: 3px;
    float: left;
}
.footer-widget .footer-post .title {
    display: block;
    font-size: 18px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
}

.footer-widget .footer-post p  {
    font-size: 13px;
    font-style: italic;
}

.footer-widget .footer-post .title ,
.footer-widget .footer-post p {margin-left: 85px;}
/*
.footer .social a {
    margin-right: 0;
    margin-left: 2px;
    width: 40px;
    height: 40px;
    line-height: 50px;
    font-size: 36px;
    background: #fff;
    color: #ddd;
    text-align: right;
    border-radius: 9px;
    overflow: hidden;
}
*/
.footer-widget.footer-contact i {
    width: 18px;
    margin-right: 4px;
    font-size: 16px;
    line-height: 34px;
    color: #fff;
}
.footer-widget.footer-contact span {
    display: inline-block;
    font-size: 14px;
    line-height: 34px;
    color: #fff;
}

.footer .copy-right {
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
    line-height: 40px;
    color: #fff;
}
.footer .copy-right a {color: #fff;}

/* Back to top */

.totop {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 104400;
}

.totop a, .totop a:visited {
    display: block;
    width: 50px;
    height: 50px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 50px;
    border-radius: 2px;
}


/* Responsive CSS */

/* Mobile phones */
@media (max-width: 480px){
    .banner .slide-two .showcase {display: none;}
    .portfolio .p-element { width: 100% !important; }
    .pricing .pricing-item .pricing-details span {
        font-size: 13px;
    }

}

/* Tablets */
@media (max-width: 767px){
    body {padding-top: 0;}
    /* Default Padding */
    .pad {padding: 80px 0px;}

    .header-info {display: none !important; }
    .navbar-header .navbar-brand img {max-width: 90px;}
    .navbar-default .navbar-nav > li > a {
        padding: 7px 15px;
        font-size: 14px;
    }

    .navbar-default .navbar-nav > li > a,
    .navbar-default .navbar-nav > li > a:hover,
    .navbar-default .navbar-nav > li > a:focus {
        border: 0px;
    }
    .navbar-default .navbar-nav > li > a:hover {background: #f5f5f5;}
    .navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-default .navbar-nav > .active > a:focus {
        border: 0px;
    }

    .navbar-default .nav .open>a, .navbar-default .nav .open>a:hover, .navbar-default .nav .open>a:focus,
    .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav>.dropdown>a:focus {
        border: 0px;
    }

    .navbar-default .nav .dropdown-menu {
        padding: 0;
        border: 0;
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 15px;
        font-size: 13px;
        border-bottom-color: #ddd;
        border-radius: 0 !important;
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li:first-child>a {border-top: 1px solid #ddd;}

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #fff;
    }

    .banner .carousel .carousel-control {display: none !important;}
    .banner .slide-one {bottom: 20px !important;}
    .banner .slide-one h2 {margin-bottom: 0;font-size: 32px !important;}
    .banner .slide-one p, .banner .slide-two p {display: none;}
    .banner .slide-two .showcase {max-width: 210px !important;}
    .banner .slide-one .btn {
        margin-top: 20px;
        padding: 7px 20px;
        font-size: 13px;
    }

    .feature .feature-item {
        max-width: 430px;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .service .service-item {
        max-width: 430px;
        margin: 0 auto;
        margin-bottom: 30px;
        text-align: center;
    }

    .member-content .member-hover-color {display: none !important;}
    .member-content .member-item .member-hover  {
        position: static;
        text-align: center;
        padding: 20px 30px;
        border-bottom-left-radius: 1px;
        border-bottom-right-radius: 1px;
    }
    .member-content .member-item .member-hover h3 {
        margin-top: 0;
    }

    .portfolio .p-element { width: 49.8%; }

    .cta .cta-content {
        max-width: 430px;
        margin: 0px auto;
    }

    .pricing-content .pricing-item {
        max-width: 430px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .about-content {
        max-width: 430px;
        margin: 0 auto;
    }

    .pricing-content .pricing-item.highlight {position: static; }

    .blog .post-content {
        max-width: 430px;
        margin: 0 auto;
    }

    .contact .contact-form,
    .contact .contact-map {
        max-width: 450px;
        margin: 0px auto;
        margin-top: 30px;
    }

    .video .video-caption {
        max-width: 430px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .video .video-container {max-width: 430px; margin: 0 auto;}

    .form-content, .map-content {
        max-width: 430px;
        margin: 0 auto;
    }

    .sign-area {margin-bottom: 50px;}
    .sign-area .nav-tabs.nav-justified>li>a {border-radius: 1px;}

    .footer-content {
        max-width: 430px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .footer .copy-right {
        display: block;
        float: none !important;
        text-align: center;
    }

}

@media (max-width: 991px) {
    .banner .slide-one {bottom: 80px;}
    .banner .slide-one h2 {font-size: 44px;}
    .banner .slide-two h3 {font-size: 32px;}
    .banner .slide-two {bottom: 30px;}
    .banner .slide-one p,.banner .slide-two p {
        font-size: 14px;
        line-height: 26px;
    }
    .banner .slide-two .showcase {max-width: 330px;}
}
