@charset "UTF-8";
/**
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
 * VARIABLES
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
/* Basic Colours */
/* Socail Colors */
/* Color for this Theme */
/* Media Queries */
/**
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
 * MIXINS
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@import url(//fonts.googleapis.com/css?family=Open+Sans:400italic,400,300,700);
@import url(//fonts.googleapis.com/css?family=PT+Sans:400,700,400italic);
@import url(//fonts.googleapis.com/css?family=Lato:400);
@import url(//fonts.googleapis.com/css?family=Cabin:400);
.banner .slide-one .caption-centered {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  vertical-align: top;
}

/* Fonts */
/* Basic CSS starts */
body {
  padding-top: 80px;
  color: #656565;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  color: #434343;
}

p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:hover, a:active, a:focus {
  outline: 0;
  outline-width: 0 !important;
}

:focus {
  outline: none;
  outline-width: 0 !important;
}

::-moz-focus-inner {
  border: 0;
}

form label {
  font-size: 13px;
  font-weight: normal;
  color: #9a9a9a;
}

form input, form input:focus,
form textarea, form textarea:focus {
  border: 1px solid #ddd !important;
  border-radius: 2px;
  box-shadow: none !important;
  font-size: 13px !important;
  color: #9a9a9a;
}

f
.social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  text-decoration: none;
  text-align: center;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.social a, .social a:hover {
  border: 0px;
  text-decoration: none;
}

a.btn {
  border-radius: 2px;
  box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.05) inset, 0 2px 3px rgba(0, 0, 0, 0.05);
}

.btn-transparent {
  color: #fff;
  font-size: 16px;
  background: transparent;
  border: 2px solid #fff !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3), inset 0 0 0 1px #fff;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.btn-transparent:hover {
  color: #474747;
  box-shadow: inset 0 -60px 0 1px #fff;
}

.overlay-pattern {
  background: url("../img/overlay-pattern.png") repeat;
}

.parallax-one {
  background: url("../img/overlay-pattern.png"), url("../img/background/1.jpg") 50% fixed;
}

.parallax-two {
  background: url("../img/overlay-pattern.png"), url("../img/background/2.jpg") 50% fixed;
}

/* Basic CSS ends */
/* style switcher */
.style-switcher {
  width: 30px;
  padding: 3px;
  position: fixed;
  top: 150px;
  left: -31px;
  z-index: 500;
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.style-switcher a {
  display: block;
  width: 18px;
  height: 18px;
  margin: 3px;
}

.style-switcher .style-btn {
  position: absolute;
  top: 15px;
  left: 100%;
  margin-left: 0;
  z-index: 501;
  width: 30px;
  height: 30px;
  line-height: 29px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  color: #565656;
  border: 1px solid #ccc;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/* tool tip */
.tooltip .tooltip-inner {
  border-radius: 2px !important;
}

/* Default Padding */
.pad {
  padding: 120px 0px;
}

/* Container Default Heading */
.container .default-header {
  text-align: center;
  margin-bottom: 80px;
}

.container .default-header i {
  display: block;
  margin: 0 auto;
  font-size: 48px;
}

.container .default-header h3 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 50px;
}

.container .default-header p {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.036em;
}

/* Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 510;
  width: 100%;
  background: #fefefe;
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 1px 10px #ddd;
}

.header-info {
  background: #fafafa;
  border-bottom: 1px solid #ddd;
}

.header-info .input-group .form-control {
  height: 42px;
  font-size: 16px !important;
  border-radius: 0;
  border-bottom: 0 !important;
  border-top: 0 !important;
  box-shadow: none;
}

.header-info .input-group .btn {
  padding: 0 14px;
  background: #f5f5f5;
  border-color: #ddd;
  border-radius: 0;
  border-bottom: 0;
  border-top: 0;
}

.header-info .input-group .btn i {
  font-size: 20px;
  line-height: 42px;
  color: #aaa;
}

.header-info .social {
  border-right: 1px solid #ddd;
}

.header-info .social a {
  float: right;
  margin: 0;
  width: 44px;
  height: 42px;
  line-height: 41px;
  font-size: 18px;
  border: none;
  border-left: 1px solid #ddd;
}

.header-info .social a:hover {
  background: #fff;
}

.header-info .social a i {
  color: #aaa;
}

/* Navigation Menu */
.navbar-default {
  margin-bottom: 0;
  background: transparent;
  border-radius: 0px;
  border: 0px;
}

.navbar-header .navbar-brand {
  padding-top: 2px;
}

.navbar-header .navbar-brand img {
  width: 100%;
  max-width: 300px;
  padding: 5px 5px 0;
}

.navbar-default .navbar-nav {
  border-right: 1px solid #ddd;
}

.navbar-default .navbar-nav > li > a {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 10px;
  font-weight: 700;
  color: #898989;
  border-left: 1px solid #ddd;
  text-transform: uppercase;
}

@media only screen and (min-width: 500px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 14px;
  }
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > .dropdown > a:focus,
.navbar-default .nav .open > a, .navbar-default .nav .open > a:hover, .navbar-default .nav .open > a:focus {
  background: transparent;
  border-color: #ddd;
}

.navbar-default .nav .dropdown-menu {
  left: 0;
  min-width: 180px;
  padding-top: 3px;
  padding-bottom: 0;
  border: 0;
  border-radius: 0px;
}

.navbar-default .nav .dropdown-menu > li > a {
  padding: 10px 12px;
  font-size: 14px;
  background: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.navbar-default .navbar-toggle {
  top: 0px;
  border-radius: 2px;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background: transparent;
}

.navbar-default > .container {
  width: 100%;
  padding: 0;
}

/* compressed header */
.header-compress .navbar-header .navbar-brand img {
  max-width: 110px;
}

.header-compress .navbar-default .navbar-nav > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* carousel banner */
.banner .carousel .carousel-control {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  display: inline-block;
  font-size: 18px;
  text-align: center;
  background: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
  border: 0;
  opacity: 1;
  text-shadow: none;
}

.banner .carousel .carousel-control span {
  width: 50px;
  height: 50px;
  line-height: 52px;
  font-size: 22px;
  background: #f8f8f8;
  border-radius: 100px;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.6), inset 0 0 0 1px #ddd;
}

.banner .slide-one {
  bottom: 150px;
  text-align: center;
}

.banner .slide-one h2 {
  margin-bottom: 20px;
  font-size: 72px;
  color: #fff;
}

.banner .slide-one p {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.banner .slide-one .btn {
  display: inline-block;
  margin-top: 30px;
  padding: 12px 32px;
  font-size: 16px;
}

.banner .slide-two {
  bottom: 80px;
}

.banner .slide-two h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 56px;
  color: #fff;
}

.banner .slide-two p {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.banner .slide-two .showcase {
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  margin-top: 20px;
}

/* feature */
.feature .feature-item {
  margin-bottom: 30px;
  text-align: center;
}

/*
.feature .feature-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation-duration: 1.25s;
    -moz-animation-duration: 1.25s;
    -ms-animation-duration: 1.25s;
    -o-animation-duration: 1.25s;
    animation-duration: 1.25s;
}

.delay-one {
    -webkit-animation-delay: 0s;
    -moz-animation-delay:0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}

.delay-two {
    -webkit-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -ms-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

.delay-three {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.delay-four {
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
}

.feature .feature-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.feature .feature-item a {
  display: block;
  width: 100%;
  text-align: center;
}

.feature .feature-item a i {
  display: inline-block;
  width: 110px;
  height: 110px;
  line-height: 102px;
  font-size: 40px;
  color: #ddd;
  border: 5px solid #ddd;
  border-radius: 150px;
  box-shadow: inset 0 0 1px 1px #fff, 0 0 1px 4px #fff;
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -ms-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}

.feature .feature-item:hover a i {
  border-color: #fff;
}

.feature .feature-item h4 {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 28px;
}

.feature .feature-item p {
  font-size: 16px;
  line-height: 28px;
}

/* Service */
.service .service-item {
  position: relative;
  top: 0;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px dashed #ddd;
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -ms-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}

.service .service-item:hover {
  top: -10px;
}

/*
.service .service-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation-duration: 1.25s;
    -moz-animation-duration: 1.25s;
    -ms-animation-duration: 1.25s;
    -o-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-delay: 0s;
    -moz-animation-delay:0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}

.service .service-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.service .service-item a i {
  display: inline-block;
  margin-right: 15px;
  width: 35px;
  text-align: center;
  font-size: 32px;
  line-height: 32px;
  color: #ddd;
}

.service .service-item a span.service-title {
  display: inline-block;
  font-size: 28px;
  line-height: 32px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
}

.service .service-item p {
  margin-top: 15px;
}

/* Portfolio */
.portfolio {
  padding-top: 100px;
}

.portfolio .container .default-header {
  margin-bottom: 40px;
}

.portfolio #filters {
  margin-bottom: 50px;
}

/**** Filter ****/
#filters {
  margin-bottom: 30px;
  text-align: center;
}

#filters li {
  display: inline;
}

#filters li a.btn {
  margin: 3px 2px;
}

/**** Isotope Filtering ****/
.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  z-index: 1;
}

/**** Isotope CSS3 transitions ****/
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}

.portfolio .p-element {
  width: 24.98%;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  float: left;
}

.portfolio .p-element .element {
  position: relative;
}

.portfolio .p-element .element img {
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  -ms-transition: all 250ms linear;
  -o-transition: all 250ms linear;
  transition: all 250ms linear;
}

.portfolio .p-element:hover .element img {
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
}

.portfolio-content .p-hover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.portfolio-content .p-element:hover .p-hover {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.portfolio-content .p-hover a {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.portfolio-content .p-hover i {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 15;
  margin-top: -20px;
  margin-left: -20px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-radius: 2px;
}

.portfolio-content .p-element h3 {
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 15;
  margin: 0;
  font-size: 18px;
  color: #fff;
  padding: 9px 12px;
  background: rgba(0, 0, 0, 0.4);
}

.portfolio-content .p-element:hover h3 {
  background: transparent;
}

/* Testimonial */
.testimonial {
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
  box-shadow: inset 0 0 6px #e1e1e1;
}

.testimonial .carousel .carousel-control {
  position: absolute;
  top: 40px;
  z-index: 100;
  display: inline-block;
  width: 25px;
  font-size: 18px;
  text-align: center;
  background: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
  border: 0;
  opacity: 1;
  text-shadow: none;
}

.testimonial .carousel .carousel-control.left {
  left: 50%;
  margin-left: -90px;
}

.testimonial .carousel .carousel-control.right {
  right: 50%;
  margin-right: -90px;
}

.testimonial .testimonial-content {
  text-align: center;
}

.testimonial .testimonial-content img {
  width: 100%;
  max-width: 90px;
  margin: 0 auto;
  margin-top: 5px;
  border: 6px solid #fdfdfd;
  border-radius: 100px;
  box-shadow: 0 0 3px 1px #ccc;
}

.testimonial .testimonial-content h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
}

.testimonial .testimonial-content p {
  max-width: 1080px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 32px;
}

.testimonial .testimonial-content p:before,
.testimonial .testimonial-content p:after {
  position: relative;
  top: -4px;
  display: inline-block;
  font-size: 20px;
  font-family: 'FontAwesome';
}

.testimonial .testimonial-content p:before {
  margin-right: 7px;
  content: "\f10d";
  /* Before Quote */
}

.testimonial .testimonial-content p:after {
  margin-left: 7px;
  content: "\f10e";
  /* After Quote */
}

/* CTA */
.cta {
  padding: 80px 0px;
  text-align: center;
}

.cta .cta-content h4 {
  margin: 0px;
  margin-bottom: 10px;
  font-size: 45px;
  color: #fff;
}

.cta .cta-content p {
  color: #fff;
}

.cta .cta-content .cta-btn {
  margin-top: 25px;
  text-align: center;
}

.cta .cta-content .cta-btn a.btn {
  font-weight: bold;
  padding: 15px 30px;
}

/* pricing block */
/*
.pricing-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
    animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay:0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.pricing-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.pricing .pricing-content .pricing-item {
  text-align: center;
  background: #fefefe;
  box-shadow: inset 0 0 0 1px #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.pricing-content .pricing-item h3 {
  margin: 0;
  padding: 30px 0;
  font-size: 32px;
  background: #eee;
  box-shadow: inset 0 0 0 1px #ddd;
}

.pricing-content .pricing-item h4 {
  margin-top: 25px;
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
}

.pricing-content .pricing-item .sub-text {
  display: block;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
  border-bottom: 1px dashed #ddd;
}

.pricing-content .pricing-item h4 sub {
  position: relative;
  font-size: 20px;
  left: 3px;
  top: 0px;
}

.pricing-content .pricing-item .pricing-details {
  margin: 15px;
  background: #fff;
  border: 1px solid #fff;
}

.pricing-content .pricing-item .pricing-details ul {
  margin: 0;
}

.pricing-content .pricing-item .pricing-details ul li {
  padding: 9px 12px;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #fff;
}

.pricing-content .pricing-item .pricing-details ul li:last-child {
  border-bottom: 0;
}

.pricing-content .pricing-item .pricing-details ul li:nth-child(odd) {
  background: #edf5f7;
}

.pricing-content .pricing-item .pricing-details ul li:nth-child(even) {
  background: #f3fbfe;
}

.pricing-content .pricing-item .pricing-details ul li i {
  margin-right: 6px;
  width: 18px;
  font-size: 18px;
}

.pricing-content .pricing-item .btn-area {
  padding: 20px 15px;
  border-top: 1px dashed #ddd;
}

.pricing-content .pricing-item .btn-area .btn {
  display: block;
  padding: 10px 0;
  font-weight: bold;
  font-size: 16px;
  border-radius: 4px;
}

.pricing-content .pricing-item.highlight {
  position: absolute;
  left: 0;
  top: -9px;
  width: 100%;
  box-shadow: inset 0 0 0 1px #ddd, 0 1px 20px #ccc;
}

.pricing-content .pricing-item.highlight h3 {
  font-size: 40px;
  color: #fff;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}

.pricing-content .pricing-item.highlight h4 {
  font-size: 32px;
}

.pricing-content .pricing-item.highlight .sub-text {
  font-size: 16px;
  padding-bottom: 16px;
}

.pricing-content .pricing-item.highlight .btn-area .btn {
  padding: 15px 0;
}

/* About Us */
.about .about-content {
  padding-bottom: 50px;
}

.about-content h3 {
  margin-top: 0;
  font-size: 32px;
}

.about-content h4 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}

.about-content img {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 30px;
}

/*
.member-content .member-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay:0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.member-content .member-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.member-content .member-item {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
}

.member-hover-color {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease-out;
  -webkit-transition-delay: 0.3s;
  /* Safari */
  -moz-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.member-content .member-item .member-hover {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease-out;
  -webkit-transition-delay: 0s;
  /* Safari */
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.member-content .member-item:hover .member-hover-color {
  top: 0;
}

.member-content .member-item:hover .member-hover {
  top: 0;
  -webkit-transition-delay: 0.5s;
  /* Safari */
  -moz-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.member-content .member-item p,
.member-content .member-item h3 a {
  color: #fff;
}

.member-content .member-item h3 {
  margin-top: 15px;
  margin-bottom: 0;
}

.member-content .member-item .deg {
  display: block;
  margin-bottom: 16px;
  color: #fff;
}

.member-content .member-item .social {
  margin-top: 15px;
}

.member-content .member-item .social a {
  width: 35px;
  height: 35px;
  margin-left: 0;
  margin-right: 3px;
  background: #fff;
  font-size: 14px;
  line-height: 33px;
  border-radius: 50px;
}

.about .quote-para {
  max-width: 880px;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
}

.about .quote-para p {
  font-size: 18px;
  line-height: 32px;
}

/* Our Counter */
.counter {
  padding: 80px 0;
}

.counter .counter-item {
  max-width: 430px;
  margin: 0px auto;
  margin-bottom: 40px;
  text-align: center;
}

.counter .counter-item h3 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 32px;
  color: #fff;
}

.counter .counter-item i {
  display: block;
  text-align: center;
  margin: 0px auto;
  margin-bottom: 20px;
  font-size: 112px;
  color: #fff;
}

.counter .counter-item span {
  display: block;
  padding: 0;
  margin: 0;
  font-size: 45px;
  color: #fff;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
}

/* blog area */
.blog {
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
  box-shadow: inset 0 0 6px #e1e1e1;
}

.entry {
  margin-bottom: 30px;
  padding-bottom: 20px;
  background: #fff;
  box-shadow: inset 0 0 0 1px #ddd;
  border-radius: 2px;
}

/*
.entry {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay:0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.entry.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.entry .entry-head {
  position: relative;
  overflow: hidden;
}

.entry .entry-head .date {
  position: absolute;
  top: 0;
  left: 30px;
  width: 85px;
  padding: 8px 0;
  text-align: center;
  font-size: 20px;
  color: #fff;
  box-shadow: 3px 3px 0 0px #fff;
}

.entry .entry-head .date strong {
  display: block;
  margin-top: 2px;
  font-size: 34px;
  line-height: 32px;
}

.entry .entry-head .meta {
  position: relative;
  padding: 8px 14px;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
}

.entry .entry-head .meta i {
  font-size: 14px;
}

.entry .entry-head .meta:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  margin-left: -7px;
  pointer-events: none;
  border-color: rgba(75, 170, 211, 0);
  border-width: 14px;
}

.entry .entry-body {
  padding: 12px 14px 0 14px;
}

.entry .entry-body .title {
  margin-top: 0;
  font-size: 22px;
  line-height: 30px;
  text-transform: capitalize;
}

/* Process css */
.process-content .process-item {
  max-width: 430px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 20px;
  background: #f8f8f8;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #ddd;
  text-align: center;
  /*
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
    */
}

/*
.process-content .process-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.process-content .process-item i {
  display: block;
  width: 130px;
  height: 130px;
  line-height: 130px;
  margin: 0px auto;
  text-align: center;
  font-size: 60px;
  color: #ddd;
  background: #fff;
  border-radius: 200px;
  box-shadow: 0 3px 3px #ddd;
}

.process-content .process-item h4 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 28px;
  text-align: center;
}

/* video */
.video {
  padding-top: 100px;
  padding-bottom: 60px;
}

.video .video-caption {
  margin-bottom: 30px;
}

.video .video-caption i {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: #fff;
  font-size: 32px;
  float: left;
  border-radius: 100px;
}

.video .video-caption h3 {
  margin-top: 0;
  margin-left: 100px;
  margin-bottom: 10px;
  font-size: 28px;
  color: #fff;
}

.video .video-caption p {
  margin-left: 100px;
  font-size: 13px;
  line-height: 23px;
  font-style: Italic;
  color: #fff;
}

/*
.video-caption,
.video .video-container {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 0s;
    -moz-animation-duration: 1s;
    -moz-animation-delay:0s;
    -ms-animation-duration: 1s;
    -ms-animation-delay: 0s;
    -o-animation-duration: 1s;
    -o-animation-delay: 0s;
    animation-duration: 1s;
    animation-delay: 0s;
}

.video-caption.animation,
.video .video-container.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
/* Contact */
.map-content .gmap {
  width: 100%;
  height: 300px;
  border: 5px solid #ddd;
  border-radius: 3px;
}

.map-content h2 {
  margin-top: 0;
  font-size: 50px;
  line-height: 45px;
  text-transform: uppercase;
}

.map-content p {
  text-transform: uppercase;
}

/*
.form-content {
    margin-top: 40px;
    padding-top: 30px;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
}

.form-content.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
*/
.form-content p {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}

.form-content form {
  margin-top: 30px;
}

.form-content form label {
  padding-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  color: #454545;
}

.form-content form .form-control {
  border-radius: 1px;
}

.form-content form .btn {
  display: inline-block;
  margin-top: 30px;
  padding: 9px 20px;
  font-size: 15px;
  border-radius: 1px;
}

#success,
#error {
  margin-top: 30px;
  display: none;
}

/* login page sign in, sign up */
.sign-area {
  max-width: 450px;
  margin: 0px auto;
  margin-top: 50px;
  margin-bottom: 150px;
  background: #fafafa;
  box-shadow: 0 7px 15px #ddd;
}

.sign-area .nav-tabs.nav-justified > li > a {
  padding: 16px 0;
  font-size: 16px;
  line-height: 20px;
  background: #fff;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}

.sign-area .nav-tabs.nav-justified > li > a i {
  font-size: 18px;
  line-height: 20px;
}

.sign-area .tab-content {
  padding: 30px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tab-content .form-area h3 {
  margin-top: 0;
  padding-bottom: 14px;
  text-align: center;
  font-size: 28px;
  border-bottom: 1px solid #ddd;
}

.tab-content form label {
  color: #434343;
  font-weight: 700;
}

.tab-content form .btn {
  padding: 7px 16px;
  font-size: 14px;
}

/* Footer */
.footer {
  padding-top: 60px;
  padding-bottom: 30px;
}

.footer a {
  color: #fff;
}

.footer-content {
  margin-bottom: 30px;
}

.footer-content .footer-widget {
  margin-bottom: 30px;
}

.footer-content .footer-widget h4 {
  margin-top: 0;
  padding-bottom: 12px;
  font-size: 24px;
  color: #fff;
}

.footer-content .footer-widget p {
  color: #fff;
}

.footer-widget .footer-post li {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #fff;
}

.footer-widget .footer-post li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.footer-widget .footer-post img {
  max-width: 70px;
  border: 3px solid #fff;
  border-radius: 3px;
  float: left;
}

.footer-widget .footer-post .title {
  display: block;
  font-size: 18px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}

.footer-widget .footer-post p {
  font-size: 13px;
  font-style: italic;
}

.footer-widget .footer-post .title,
.footer-widget .footer-post p {
  margin-left: 85px;
}

/*
.footer .social a {
    margin-right: 0;
    margin-left: 2px;
    width: 40px;
    height: 40px;
    line-height: 50px;
    font-size: 36px;
    background: #fff;
    color: #ddd;
    text-align: right;
    border-radius: 9px;
    overflow: hidden;
}
*/
.footer-widget.footer-contact i {
  width: 18px;
  margin-right: 4px;
  font-size: 16px;
  line-height: 34px;
  color: #fff;
}

.footer-widget.footer-contact span {
  display: inline-block;
  font-size: 14px;
  line-height: 34px;
  color: #fff;
}

.footer .copy-right {
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
  line-height: 40px;
  color: #fff;
}

.footer .copy-right a {
  color: #fff;
}

/* Back to top */
.totop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 104400;
}

.totop a, .totop a:visited {
  display: block;
  width: 50px;
  height: 50px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  border-radius: 2px;
}

/* Responsive CSS */
/* Mobile phones */
@media (max-width: 480px) {
  .banner .slide-two .showcase {
    display: none;
  }
  .portfolio .p-element {
    width: 100% !important;
  }
  .pricing .pricing-item .pricing-details span {
    font-size: 13px;
  }
}

/* Tablets */
@media (max-width: 767px) {
  body {
    padding-top: 0;
  }
  /* Default Padding */
  .pad {
    padding: 80px 0px;
  }
  .header-info {
    display: none !important;
  }
  .navbar-header .navbar-brand img {
    max-width: 90px;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 7px 15px;
    font-size: 14px;
  }
  .navbar-default .navbar-nav > li > a,
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus {
    border: 0px;
  }
  .navbar-default .navbar-nav > li > a:hover {
    background: #f5f5f5;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    border: 0px;
  }
  .navbar-default .nav .open > a, .navbar-default .nav .open > a:hover, .navbar-default .nav .open > a:focus,
  .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > .dropdown > a:focus {
    border: 0px;
  }
  .navbar-default .nav .dropdown-menu {
    padding: 0;
    border: 0;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    font-size: 13px;
    border-bottom-color: #ddd;
    border-radius: 0 !important;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li:first-child > a {
    border-top: 1px solid #ddd;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
  }
  .banner .carousel .carousel-control {
    display: none !important;
  }
  .banner .slide-one {
    bottom: 20px !important;
  }
  .banner .slide-one h2 {
    margin-bottom: 0;
    font-size: 32px !important;
  }
  .banner .slide-one p, .banner .slide-two p {
    display: none;
  }
  .banner .slide-two .showcase {
    max-width: 210px !important;
  }
  .banner .slide-one .btn {
    margin-top: 20px;
    padding: 7px 20px;
    font-size: 13px;
  }
  .feature .feature-item {
    max-width: 430px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .service .service-item {
    max-width: 430px;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
  }
  .member-content .member-hover-color {
    display: none !important;
  }
  .member-content .member-item .member-hover {
    position: static;
    text-align: center;
    padding: 20px 30px;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
  }
  .member-content .member-item .member-hover h3 {
    margin-top: 0;
  }
  .portfolio .p-element {
    width: 49.8%;
  }
  .cta .cta-content {
    max-width: 430px;
    margin: 0px auto;
  }
  .pricing-content .pricing-item {
    max-width: 430px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .about-content {
    max-width: 430px;
    margin: 0 auto;
  }
  .pricing-content .pricing-item.highlight {
    position: static;
  }
  .blog .post-content {
    max-width: 430px;
    margin: 0 auto;
  }
  .contact .contact-form,
  .contact .contact-map {
    max-width: 450px;
    margin: 0px auto;
    margin-top: 30px;
  }
  .video .video-caption {
    max-width: 430px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .video .video-container {
    max-width: 430px;
    margin: 0 auto;
  }
  .form-content, .map-content {
    max-width: 430px;
    margin: 0 auto;
  }
  .sign-area {
    margin-bottom: 50px;
  }
  .sign-area .nav-tabs.nav-justified > li > a {
    border-radius: 1px;
  }
  .footer-content {
    max-width: 430px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .footer .copy-right {
    display: block;
    float: none !important;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .banner .slide-one {
    bottom: 80px;
  }
  .banner .slide-one h2 {
    font-size: 44px;
  }
  .banner .slide-two h3 {
    font-size: 32px;
  }
  .banner .slide-two {
    bottom: 30px;
  }
  .banner .slide-one p, .banner .slide-two p {
    font-size: 14px;
    line-height: 26px;
  }
  .banner .slide-two .showcase {
    max-width: 330px;
  }
}

/* Less Style sheet */
/* Colors */
.black {
  color: #555555;
}

.red {
  color: #e63131;
}

.green {
  color: #59ae26;
}

.lblue {
  color: #1ec4d8;
}

.blue {
  color: #0275d7;
}

.brown {
  color: #c27256;
}

.orange {
  color: #ed450c;
}

.purple {
  color: #852b9c;
}

.pink {
  color: #fb226f;
}

.yellow {
  color: #ffd800;
}

/* Background */
.br-black {
  background: #555555;
}

.br-red {
  background: #e63131;
}

.br-green {
  background: #59ae26;
}

.br-lblue {
  background: #1ec4d8;
}

.br-blue {
  background: #0275d7;
}

.br-brown {
  background: #c27256;
}

.br-orange {
  background: #ed450c;
}

.br-purple {
  background: #852b9c;
}

.br-pink {
  background: #fb226f;
}

.br-yellow {
  background: #ffd800;
}

/* General CSS */
a {
  color: #59ae26;
}

a:hover {
  color: #3f910d;
}

a:hover, a:active, a:focus {
  color: #3f910d;
}

.btn-theme {
  background: #59ae26;
  color: #fff !important;
}

.btn-theme:hover {
  background: #3f910d;
}

/* Block Default Header */
.container .default-header i {
  color: #59ae26;
}

/* Main Header */
.header-info .social a {
  color: #59ae26;
}

.header-info .social a:hover i {
  color: #59ae26;
}

/* Navigation Bar */
.navbar-default .navbar-nav li a:hover {
  color: #59ae26;
}

.navbar-default .navbar-nav li a:active {
  color: #59ae26;
}

.navbar-default .navbar-nav li a:focus {
  color: #59ae26;
}

.navbar-default .navbar-nav .dropdown a:hover .caret, .navbar-default .navbar-nav .dropdown a:focus .caret, .navbar-default .navbar-nav .dropdown a:active .caret {
  color: #3f910d;
}

.navbar-default .navbar-nav .dropdown .dropdown-menu {
  background: #59ae26;
}

.navbar-default .navbar-nav .dropdown .dropdown-menu:after {
  border-bottom-color: #59ae26 !important;
}

.navbar-default .navbar-nav .dropdown .dropdown-menu li a {
  color: #59ae26;
  border-bottom: 1px solid #59ae26;
}

.navbar-default .navbar-nav .dropdown .dropdown-menu li a:hover {
  background: #59ae26;
  color: #ffffff;
}

.navbar-default .nav .open a {
  color: #59ae26;
}

.navbar-default .nav .open a:hover, .navbar-default .nav .open a:active, .navbar-default .nav .open a:focus {
  color: #59ae26;
}

.navbar-default .navbar-toggle {
  border-color: #59ae26;
}

.navbar-default .navbar-toggle .icon-bar {
  background: #59ae26;
}

/* banner */
.banner .carousel .carousel-control span {
  color: #59ae26;
}

.banner .carousel .carousel-control span:hover {
  color: #3f910d;
}

/* Features */
.feature .feature-item:hover a i {
  color: #59ae26;
  box-shadow: inset 0 0 1px 1px #59ae26, 0 0 1px 4px #59ae26;
}

/* Service */
.service .service-item:hover a i {
  color: #59ae26;
}

/* Pricing */
.pricing .pricing-item.highlight h3 {
  background: #59ae26;
}

/* Portfolio */
.portfolio-content .p-hover i {
  background: #59ae26;
}

/* video */
.video .video-caption i {
  background: #59ae26;
}

/* Testimonial */
.testimonial .carousel .carousel-control {
  color: #59ae26;
}

.testimonial .testimonial-content p:before {
  color: #59ae26;
}

.testimonial .testimonial-content p:after {
  color: #59ae26;
}

/* About Us */
.about .about-content h3 {
  color: #59ae26;
}

.about .member-content .member-item .member-hover-color {
  background: #59ae26;
}

.about .member-content .member-item .social a {
  border: 1px solid #3f910d;
}

@media (max-width: 767px) {
  .member-hover {
    background: #59ae26;
  }
}

/* Process */
.process .process-content .process-item {
  border-bottom: 5px solid #59ae26;
}

.process .process-content .process-item:hover i {
  color: #59ae26;
}

/* Counter */
.counter .counter-item:hover i {
  color: #59ae26;
}

/* Blog Page */
.blog .entry .entry-head .date {
  background: #59ae26;
}

.blog .entry .entry-head .meta {
  background: #59ae26;
}

.blog .entry .entry-head .meta:after {
  border-bottom-color: #59ae26;
}

/* Contact */
.contact .contact-form form input {
  color: #59ae26;
}

.contact .contact-form form textarea {
  color: #59ae26;
}

/* sign area */
.sign-area .nav-tabs.nav-justified > .active > a,
.sign-area .nav-tabs.nav-justified > .active > a:hover,
.sign-area .nav-tabs.nav-justified > .active > a:focus {
  background: #3f910d;
  color: #fff;
  border-color: #3f910d;
  border-bottom-color: transparent;
}

.sign-area .nav-tabs.nav-justified > li > a {
  border-color: #59ae26;
}

.sign-area .nav-tabs.nav-justified > li > a:hover {
  background: #59ae26;
  color: #fff;
  border-color: #59ae26;
}

.sign-area .tab-content {
  border: 1px solid #59ae26;
  border-top: 0;
}

@media (max-width: 767px) {
  .sign-area .tab-content {
    border-top: 1px solid #59ae26;
  }
}

/* Footer */
.footer {
  background: #59ae26;
  border-top: 1px solid #3f910d;
  border-bottom: 5px solid #3f910d;
}

.footer .footer-content {
  border-bottom: 1px solid #3f910d;
}

.footer .footer-content .footer-widget h4 {
  border-bottom: 1px solid #3f910d;
}

.footer .social a:hover {
  color: #59ae26;
}

/* To Top */
.totop a {
  background: #3f910d;
}

.totop a:visited {
  background: #3f910d;
}

/* CUSTOM */
body {
  padding-top: 50px;
}

@media only screen and (min-width: 767px) {
  body {
    padding-top: 80px;
  }
}

.row {
  min-width: 100%;
}

.header {
  max-height: 100%;
  overflow-y: visible;
}

.navbar-header {
  overflow: hidden;
}

.navbar-brand {
  float: left;
  font-size: 18px;
  height: 50px;
  line-height: 20px;
  padding: 15px;
}

@media only screen and (min-width: 767px) {
  .navbar-brand {
    height: 70px;
  }
}

.navbar-header .navbar-brand {
  padding-top: 2px;
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  margin: 0;
  padding: 0 0 0 7px;
}

.navbar-header .navbar-brand img {
  max-width: 200px;
  padding: 6px 0 2px 22px;
  width: 100%;
}

@media only screen and (min-width: 767px) {
  .navbar-header .navbar-brand img {
    max-width: 250px;
    padding: 15px 0 0 5px;
  }
}

@media (min-width: 900px) {
  .navbar-header .navbar-brand img {
    max-width: 300px;
    padding: 10px 0 10px 10px;
  }
}

.header-compress .navbar-header .navbar-brand img {
  max-width: 200px;
  padding: 6px 0 0px 15px;
  width: 100%;
}

@media only screen and (min-width: 767px) {
  .header-compress .navbar-header .navbar-brand img {
    max-width: 250px;
    padding: 8px 0 0 8px;
  }
}

.header-compress .navbar-default .navbar-nav > li > a {
  padding: 24px 40px !important;
}

@media only screen and (min-width: 500px) {
  .header-compress .navbar-default .navbar-nav > li > a {
    padding: 25px 9px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .header-compress .navbar-default .navbar-nav > li > a {
    padding: 25px 20px !important;
  }
}

.header-compress .navbar-header .navbar-nav li .nav-page {
  padding: 21px 15px !important;
}

@media only screen and (min-width: 767px) {
  .site-npis .header-compress .navbar-header .navbar-brand img {
    max-width: 270px;
    padding: 7px 0 0 0;
  }
}

.navbar-default .navbar-nav > .current > a {
  color: #3f910d;
  font-size: 16px;
  font-weight: 700;
}

.navbar-toggle {
  margin-right: 23px;
  padding: 9px 8px;
}

.site-nppr .navbar-default .navbar-nav > li {
  text-align: left;
}

@media only screen and (min-width: 767px) {
  .site-nppr .navbar-default .navbar-nav > li {
    min-width: 48px;
    text-align: center;
  }
}

@media screen and (min-width: 850px) {
  .site-nppr .navbar-default .navbar-nav > li {
    min-width: 64px;
  }
}

.site-nppr .navbar-default .navbar-nav > li a {
  margin-bottom: -1px;
}

.navbar-default .navbar-nav li .nav-page {
  color: #59ae26;
  font-size: 17px;
  font-weight: 800;
  line-height: 20px;
  padding: 10px 25px;
  border-bottom: 3px double;
}

@media only screen and (min-width: 767px) {
  .navbar-default .navbar-nav li .nav-page {
    padding: 30px 8px;
    border-bottom: 0 none transparent;
  }
}

@media only screen and (min-width: 1024px) {
  .navbar-default .navbar-nav li .nav-page {
    padding: 30px 15px;
  }
}

.navbar-default .navbar-nav li .nav-active {
  text-decoration: underline;
}

.navbar-default .navbar-nav li a {
  font-size: 17px;
  font-weight: 500;
  padding: 10px 35px;
  border-bottom: 1px groove rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 767px) {
  .navbar-default .navbar-nav li a {
    font-size: 9px;
    padding: 30px 8px;
    border-bottom: 0 none transparent;
  }
}

@media only screen and (min-width: 1024px) {
  .navbar-default .navbar-nav li a {
    font-size: 12px;
    padding: 30px 15px;
  }
}

.navbar-default .nav .dropdown-menu {
  border-top: 1px solid #ddd;
  top: 102%;
}

.navbar-default .nav .open .dropdown-menu > li > a {
  background: none repeat scroll 0 0 #ececec;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  padding: 10px 50px;
}

@media only screen and (min-width: 767px) {
  .navbar-default .nav .open .dropdown-menu > li > a {
    background: none repeat scroll 0 0 #fff;
    border-bottom: 1px solid;
    color: #898989;
    padding: 10px 15px;
  }
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
  color: #3f910d;
  background-color: #DDD;
}

.navbar-nav li a .btn {
  background-color: transparent;
  color: #999 !important;
  font-size: 16px;
  padding: 0;
}

@media only screen and (min-width: 767px) {
  .navbar-nav li a .btn {
    color: #fff !important;
    background-color: #59ae26;
    padding: 6px 12px;
  }
}

@media only screen and (min-width: 767px) {
  .navbar-nav > li:nth-of-type(6) ul {
    margin-left: -65px;
    right: 0;
  }
}

.navbar-nav li .btn-theme {
  margin: -8px 0;
}

.navbar .navbar-nav .dropdown-menu > li:first-of-type a {
  background-color: #eee;
  font-weight: 800;
}

.pad {
  padding: 40px 0px;
}

@media only screen and (min-width: 767px) {
  .pad {
    padding: 60px 0px;
  }
}

@media only screen and (min-width: 1400px) {
  .pad {
    padding: 90px 0px;
  }
}

.container .default-header {
  margin: 0 0 70px;
  padding: 0;
}

.container .default-header h3 {
  font-size: 30px;
  margin: 40px 0;
}

@media only screen and (min-width: 767px) {
  .container .default-header h3 {
    font-size: 50px;
  }
}

.container .default-header p {
  font-size: 14px;
  letter-spacing: 0.036em;
  line-height: 30px;
}

@media only screen and (min-width: 767px) {
  .container .default-header p {
    font-size: 18px;
  }
}

.cta .cta-content h4 {
  color: #fff;
  font-size: 30px;
  margin: 0 0 10px;
}

@media only screen and (min-width: 767px) {
  .cta .cta-content h4 {
    font-size: 45px;
  }
}

.cta .cta-content .row > div {
  margin: 0 5%;
  min-width: 90%;
}

@media only screen and (min-width: 1024px) {
  .cta .cta-content .row > div {
    margin: 0;
    min-width: 0;
  }
}

.video {
  padding-bottom: 70px;
  padding-top: 70px;
}

.video-content > .row > div {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .video-content > .row > div {
    width: 50%;
  }
}

.video .video-container {
  margin: 0 auto;
}

.video .video-caption {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.36);
  box-shadow: -2px -2px 10px -3px rgba(0, 0, 0, 0.5) inset;
  margin-bottom: 5px;
}

.video-caption img {
  display: none;
  height: 82px;
}

@media only screen and (min-width: 500px) {
  .video-caption img {
    display: inline-block;
  }
}

@media only screen and (min-width: 1200px) {
  .video-caption img {
    height: auto;
  }
}

.video .video-caption h3 {
  display: inline-block;
  margin: 0;
  padding: 2rem;
  vertical-align: middle;
  font-size: 20px;
}

@media only screen and (min-width: 1024px) {
  .video .video-caption h3 {
    font-size: 24px;
  }
}

.testimonial .carousel-inner {
  padding-bottom: 10px;
  min-height: 150px;
}

.testimonial .carousel .carousel-control {
  bottom: 6px;
  top: auto;
  margin: auto;
}

.testimonial .carousel .carousel-control.left {
  left: 50%;
  margin-left: -70px;
}

.testimonial .carousel .carousel-control.right {
  right: 50%;
  margin-right: -70px;
}

.testimonial .testimonial-content p {
  font-style: italic;
  letter-spacing: 0.5px;
}

.testimonial .testimonial-content p:before,
.testimonial .testimonial-content p:after {
  font-size: 11px;
  padding: 0 1em;
  top: -7px;
}

.testimonial .item h3 {
  font-weight: 100;
}

.testimonial .item h3 a {
  font-weight: 700;
}

.testimonial .item h3 span {
  display: block;
}

@media only screen and (min-width: 767px) {
  .testimonial .item h3 span {
    display: inline-block;
  }
}

.testimonial .testimonial-content i {
  font-size: 40px;
  color: #434343;
}

.footer {
  background: url("../img/textures/bg_black-orchid.png") repeat scroll 0 0 #1f3e56;
}

.footer-widget .footer-post li {
  border-bottom: 1px dashed #777;
}

.footer-widget .footer-post img {
  display: none;
}

@media only screen and (min-width: 767px) {
  .footer-widget .footer-post img {
    border: 0 none transparent;
    max-width: 100px;
  }
}

@media only screen and (min-width: 1024px) {
  .footer-widget .footer-post img {
    display: block;
  }
}

.footer-widget .footer-post .title,
.footer-widget .footer-post p {
  line-height: 20px;
  margin-left: 0;
}

@media only screen and (min-width: 767px) {
  .footer-widget .footer-post .title,
  .footer-widget .footer-post p {
    line-height: 38px;
  }
}

@media only screen and (min-width: 1024px) {
  .footer-widget .footer-post .title,
  .footer-widget .footer-post p {
    margin-left: 112px;
  }
}

.footer-widget .footer-post .title {
  font-size: 14px;
}

@media only screen and (min-width: 767px) {
  .footer-widget .footer-post .title {
    font-size: 18px;
  }
}

.form-content {
  margin-top: 60px;
  padding-top: 0;
}

.footer-widget.footer-contact span {
  line-height: 25px;
}

.footer-widget.footer-contact strong {
  color: #3f910d;
  display: block;
  font-size: 18px;
  margin-bottom: 6px;
}

.site-npis .footer-widget.footer-contact strong {
  font-size: 16px;
}

.footer-widget.footer-contact .fa-phone {
  margin-top: 10px;
}

.footer-widget.footer-contact .fa-fax {
  margin-bottom: 10px;
}

.footer-widget.footer-contact .social i {
  margin-right: 10px;
  font-size: 18px;
}

.footer-widget.footer-contact .social i.fa-envelope-o {
  color: #8F8F8D;
}

.footer-widget.footer-contact .social i.fa-linkedin {
  color: #0177B5;
}

.footer-widget.footer-contact .social i.fa-yelp {
  color: #C41200;
}

.footer-widget.footer-contact .social i.fa-facebook {
  color: #3B5998;
}

.footer-widget.footer-contact .social i.fa-twitter {
  color: #5EA9DD;
}

.footer-content {
  max-width: 90%;
}

@media screen and (min-width: 768px) {
  .footer-content {
    max-width: 100%;
  }
}

.footer .pull-right,
.footer .pull-left {
  line-height: 20px;
}

.portfolio {
  padding-top: 50px;
}

.portfolio #filters {
  margin-bottom: 55px;
}

.portfolio-content .p-element h3 {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
  font-size: 24px;
}

.portfolio-content .p-hover span {
  background-color: #59ae26;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  height: 45px;
  left: 50%;
  line-height: 45px;
  margin-left: -60px;
  margin-top: -20px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 120px;
  z-index: 15;
}

.portfolio-content li .btn {
  width: 90%;
  margin: 0 0 0 5%;
}

@media only screen and (min-width: 500px) {
  .portfolio-content li .btn {
    width: auto;
    margin: inherit;
  }
}

.below-process {
  font-size: 1.2em;
  font-style: oblique;
  color: #3f910d;
}

.below-process > p {
  padding: 0 1em;
}

.below-process > div {
  display: none;
}

@media screen and (min-width: 993px) {
  .below-process > div {
    display: inline-block;
    max-width: 100%;
    min-width: 0%;
  }
}

.below-process .process-info {
  margin: -15px auto 20px;
  text-align: center;
}

.below-process .process-info::after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #59ae26 #59ae26 transparent;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 1px 0;
  content: "";
  display: block;
  height: 1em;
  margin-top: 0.5em;
  width: 100%;
}

.below-process .process-info div > span {
  display: inline-block;
  height: 25px;
  margin: 0 15%;
  padding: 14px 0 5px;
  text-align: center;
  width: 70%;
}

.process-content .process-item {
  min-height: 320px;
}

.process-content .process-item > p {
  margin: 0.7em;
}

.process-content .process-item i {
  color: #59ae26;
}

.process-content .process-item i:hover {
  color: #3f910d;
}

.process-item.process-active {
  background: url("../img/textures/bg_cream-pixels.png") repeat scroll 0 0 #f4f8f0;
}

#netpay-advantage img {
  display: inline-block;
  width: 85%;
  height: auto;
}

.getstarted-content > div > div > div::after {
  bottom: 0;
  color: #ddd;
  content: "›";
  display: inline-block;
  font-size: 140px;
  height: 0;
  line-height: 0;
  margin: auto;
  position: absolute;
  right: 0.2em;
  text-shadow: 0.15em 0 0 #ddd;
  top: 0;
  vertical-align: top;
  width: 0;
  z-index: 9;
}

.pricing .getstarted-content .getstarted-item {
  text-align: center;
  background: #fefefe;
  box-shadow: inset 0 0 0 1px #ddd;
  border-radius: 4px;
}

.getstarted-content .getstarted-item h3 {
  margin: 0;
  padding: 30px 0;
  font-size: 32px;
  background: #eee;
  box-shadow: inset 0 0 0 1px #ddd;
}

.getstarted-content .getstarted-item h4 {
  margin-top: 25px;
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
}

.getstarted-content .getstarted-item .sub-text {
  display: block;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
  border-bottom: 1px dashed #ddd;
}

.getstarted-content .getstarted-item h4 sub {
  position: relative;
  font-size: 20px;
  left: 3px;
  top: 0px;
}

.getstarted-content .getstarted-item .getstarted-details {
  margin: 15px;
  background: #fff;
  border: 1px solid #fff;
}

.getstarted-content .getstarted-item .getstarted-details ul {
  margin: 0;
}

.getstarted-content .getstarted-item .getstarted-details ul li {
  padding: 9px 12px;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #fff;
}

.getstarted-content .getstarted-item .getstarted-details ul li:last-child {
  border-bottom: 0;
}

.getstarted-content .getstarted-item .getstarted-details ul li:nth-child(odd) {
  background: #edf5f7;
}

.getstarted-content .getstarted-item .getstarted-details ul li:nth-child(even) {
  background: #f3fbfe;
}

.getstarted-content .getstarted-item .getstarted-details ul li i {
  margin-right: 6px;
  width: 18px;
  font-size: 18px;
}

.getstarted-content .getstarted-item .btn-area {
  padding: 20px 15px;
  border-top: 1px dashed #ddd;
}

.getstarted-content .getstarted-item .btn-area .btn {
  display: block;
  padding: 10px 0;
  font-weight: bold;
  font-size: 16px;
  border-radius: 4px;
}

.getstarted-content .getstarted-item.highlight {
  position: absolute;
  left: 0;
  top: -9px;
  width: 100%;
  box-shadow: inset 0 0 0 1px #ddd, 0 1px 20px #ccc;
}

.getstarted-content .getstarted-item.highlight h3 {
  font-size: 40px;
  color: #fff;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  background: #59ae26;
}

.getstarted-content .getstarted-item.highlight h4 {
  font-size: 32px;
}

.getstarted-content .getstarted-item.highlight .sub-text {
  font-size: 16px;
  padding-bottom: 16px;
}

.getstarted-content .getstarted-item.highlight .btn-area .btn {
  padding: 15px 0;
}

.getstarted-content > div > div:last-of-type > div::after {
  display: none;
}

.getstarted-item span {
  margin-top: 1em;
  display: inline-block;
}

.getstarted-content .getstarted-item.highlight {
  top: 0;
  position: relative;
}

.getstarted-content .getstarted-item.highlight h3 {
  font-size: 32px;
}

.getstarted-content .getstarted-item.highlight .btn-area .btn {
  padding: 10px 0;
}

.service .service-item {
  min-height: 230px;
}

.page-resources .service .service-item {
  min-height: 150px;
}

.service .service-item p {
  margin-top: 15px;
  font-size: 13px;
  color: #555555;
}

#payroll-features .panels-blocks li span {
  font-size: 12px;
}

.netpay-green {
  background: url("../img/textures/overlay-pattern.png") repeat scroll 0 0%, url("../img/bgs/4.jpg") no-repeat fixed 50% center/cover transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .netpay-green {
    background-attachment: fixed;
  }
}

.netpay-green * {
  color: #fff;
  text-shadow: 1px 1px 0 #333;
}

.netpay-green h3 {
  font-size: 30px;
  margin: 0 0 10px;
}

.netpay-green h4 {
  font-family: "Open Sans",sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.netpay-green img {
  max-width: 90%;
}

.netpay-green-content > div > div > ul {
  list-style: outside none none;
  padding: 0 0 0 1em;
}

.netpay-green-content > div > div > ul li {
  font-size: 16px;
  font-weight: 600;
  padding: 0.5em 0;
}

.netpay-green-content > div > div > ul li i {
  font-size: 12px;
  line-height: 0;
  padding-right: 0.5em;
}

.bg-dark {
  background: none repeat scroll 0 0 #f8f8f8;
  border-top: 1px solid #e5e5e5;
  box-shadow: 0 0 6px #e1e1e1 inset;
}

.video-description h3, .video-description h4, .video-description p {
  color: white;
}

.video-description h3 {
  margin-top: 40px;
}

@media only screen and (min-width: 1024px) {
  .video-description h3 {
    margin-top: 0;
  }
}

.video-description h4 {
  margin-top: 0;
}

.banner {
  position: relative;
  overflow: hidden;
}

.overlay-pattern {
  background: url("../img/textures/overlay-pattern.png") repeat;
}

.parallax-cta {
  background: url("../img/textures/overlay-pattern.png"), url("../img/bgs/1.jpg") 50% fixed;
  overflow: hidden;
}

.parallax-cta ~ .parallax-cta {
  background: url("../img/textures/overlay-pattern.png"), url("../img/bgs/2.jpg") 50% fixed;
}

.parallax-cta ~ .parallax-cta ~ .parallax-cta {
  background: url("../img/textures/overlay-pattern.png"), url("../img/bgs/3.jpg") 50% fixed;
}

.parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta {
  background: url("../img/textures/overlay-pattern.png"), url("../img/bgs/4.jpg") 50% fixed;
}

.parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta {
  background: url("../img/textures/overlay-pattern.png"), url("../img/bgs/5.jpg") 50% fixed;
}

.parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta {
  background: url("../img/textures/overlay-pattern.png"), url("../img/bgs/6.jpg") 50% fixed;
}

div[class*="parallax-banner"] {
  height: 300px;
  position: relative;
  background-size: 125% 540px;
}

@media only screen and (min-width: 500px) {
  div[class*="parallax-banner"] {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media only screen and (min-width: 500px) and (-webkit-min-device-pixel-ratio: 1), only screen and (min-width: 500px) and (-webkit-min-device-pixel-ratio: 2) {
  div[class*="parallax-banner"] {
    background-attachment: fixed;
  }
}

@media only screen and (min-width: 767px) {
  div[class*="parallax-banner"] {
    height: 350px;
  }
}

@media only screen and (min-width: 1024px) {
  div[class*="parallax-banner"] {
    font-size: 64px;
  }
}

@media only screen and (min-width: 1400px) {
  div[class*="parallax-banner"] {
    font-size: 72px;
  }
}

@media only screen and (min-height: 300px) and (min-width: 767px) {
  div[class*="parallax-banner"] {
    height: 225px;
  }
}

@media only screen and (min-height: 400px) and (min-width: 767px) {
  div[class*="parallax-banner"] {
    height: 275px;
  }
}

@media only screen and (min-height: 500px) and (min-width: 767px) {
  div[class*="parallax-banner"] {
    height: 375px;
  }
}

@media only screen and (min-height: 600px) and (min-width: 767px) {
  div[class*="parallax-banner"] {
    height: 475px;
  }
}

@media only screen and (min-height: 700px) and (min-width: 767px) {
  div[class*="parallax-banner"] {
    height: 575px;
  }
}

.parallax-banner-payroll {
  background: url("../img/banner/pages/1.jpg") no-repeat 50% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-payroll {
    background-attachment: fixed;
  }
}

.parallax-banner-hr {
  background: url("../img/banner/pages/2.jpg") 50% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-hr {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance {
  background: url("../img/banner/insurance/8.jpg") 50% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance {
    background-attachment: fixed;
  }
}

.parallax-banner-resources {
  background: url("../img/banner/pages/4.jpg") no-repeat fixed right top transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-resources {
    background-attachment: fixed;
  }
}

.parallax-banner-company {
  background: url("../img/banner/pages/5.jpg") repeat fixed 50% center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-company {
    background-attachment: fixed;
  }
}

.banner-error {
  background: url("/img/banner/home/10.jpg") repeat scroll 50% 50% transparent;
  background-size: cover;
  max-height: 500px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .banner-error {
    background-attachment: fixed;
  }
}

/* Insurance Site Banners */
.parallax-banner-insurance_home {
  background: url("../img/banner/insurance/14.jpg") repeat fixed top center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_home {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance_workers-comp {
  background: url("../img/banner/insurance/3.jpg") repeat fixed top center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_workers-comp {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance_business {
  background: url("../img/banner/insurance/7.jpg") repeat fixed top center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_business {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance_life {
  background: url("../img/banner/insurance/12.jpg") repeat fixed 50% center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_life {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance_medical {
  background: url("../img/banner/insurance/5.jpg") repeat fixed 50% center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_medical {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance_retirement {
  background: url("../img/banner/insurance/17.jpg") repeat fixed right top transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_retirement {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance_cyber {
  background: url("../img/banner/insurance/18.jpg") repeat fixed 50% center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_cyber {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance_auto {
  background: url("../img/banner/insurance/25.jpg") repeat fixed 50% center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_auto {
    background-attachment: fixed;
  }
}

.parallax-banner-insurance_home-renters {
  background: url("../img/banner/insurance/21.jpg") repeat fixed 50% center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-insurance_home-renters {
    background-attachment: fixed;
  }
}

/* Home and Renters Site Banners */
.parallax-banner-hris {
  background: url("../img/banner/insurance/21.jpg") repeat fixed 50% center transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1), screen and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-banner-hris {
    background-attachment: fixed;
  }
}

.banner .carousel .carousel-control {
  width: 10%;
}

.banner .item > img {
  min-height: 200px;
}

.banner .slide-one {
  bottom: 0;
  color: #4a4a4a;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  height: 100%;
  width: 100%;
}

.banner .slide-one .caption-centered h2, .banner .slide-one .caption-centered p {
  margin: 0 0 12px 0;
  padding: 0 12px;
  text-shadow: 0 0 1px #000, 2px 2px 1px #fff, 2px -2px 1px #fff, -2px 2px 1px #fff, -2px -2px 1px #fff, 1px 1px 2px #fff, 0 0 15px #fff, 1px 1px 2px #000, 0 0 3px #000;
}

@media only screen and (min-width: 500px) {
  .banner .slide-one .caption-centered h2, .banner .slide-one .caption-centered p {
    margin: 0 0 16px 0;
    padding: 0 16px;
  }
}

@media only screen and (min-width: 767px) {
  .banner .slide-one .caption-centered h2, .banner .slide-one .caption-centered p {
    margin: 0 0 20px 0;
    padding: 0 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .banner .slide-one .caption-centered h2, .banner .slide-one .caption-centered p {
    margin: 0 0 24px 0;
    padding: 0 24px;
  }
}

@media only screen and (min-width: 1400px) {
  .banner .slide-one .caption-centered h2, .banner .slide-one .caption-centered p {
    padding: 0 26px;
  }
}

.banner .slide-one .caption-centered h2 {
  font-size: 18px;
  font-weight: 400;
  margin-top: 0;
  letter-spacing: -2px;
  color: #3f910d;
}

@media only screen and (min-width: 500px) {
  .banner .slide-one .caption-centered h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 767px) {
  .banner .slide-one .caption-centered h2 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 1024px) {
  .banner .slide-one .caption-centered h2 {
    font-size: 64px;
  }
}

@media only screen and (min-width: 1200px) {
  .banner .slide-one .caption-centered h2 {
    font-size: 76px;
  }
}

@media only screen and (min-width: 1400px) {
  .banner .slide-one .caption-centered h2 {
    font-size: 82px;
    line-height: 120px;
    word-spacing: 18px;
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1600px) {
  .banner .slide-one .caption-centered h2 {
    font-size: 95px;
  }
}

.banner .slide-one .caption-centered p {
  font-weight: 100;
  line-height: 1.5em;
  word-spacing: 3px;
  color: #555;
  display: block;
  padding: 0 2em;
}

@media only screen and (min-width: 500px) {
  .banner .slide-one .caption-centered p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 767px) {
  .banner .slide-one .caption-centered p {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1024px) {
  .banner .slide-one .caption-centered p {
    font-size: 26px;
    margin: 20px 0 5px;
  }
}

@media only screen and (min-width: 1400px) {
  .banner .slide-one .caption-centered p {
    font-size: 28px;
  }
}

@media screen and (min-width: 1600px) {
  .banner .slide-one .caption-centered p {
    font-size: 32px;
  }
}

.banner .slide-one .caption-centered .btn {
  margin: 0 auto;
  display: inline-block;
  font-size: 14px;
  padding: 6px 11px;
}

@media only screen and (min-width: 767px) {
  .banner .slide-one .caption-centered .btn {
    font-size: 20px;
    padding: 13px 22px;
  }
}

.banner .slide-one .caption-centered .btn-default {
  color: #3f910d;
  background-color: #fff;
  text-shadow: 0 0 0;
}

.parallax-banner-company .slide-one,
.parallax-banner-resources .slide-one,
.parallax-banner-payroll .slide-one,
.parallax-banner-insurance .slide-one,
.parallax-banner-hr .slide-one,
.parallax-banner-insurance_home .slide-one,
.parallax-banner-insurance_workers-comp .slide-one,
.parallax-banner-insurance_business .slide-one,
.parallax-banner-insurance_life .slide-one,
.parallax-banner-insurance_medical .slide-one,
.parallax-banner-insurance_retirement .slide-one,
.parallax-banner-insurance .slide-one,
.parallax-banner-insurance_auto .slide-one,
.parallax-banner-insurance_cyber .slide-one,
.parallax-banner-insurance_home-renters .slide-one,
.parallax-banner-hris .slide-one {
  background: transparent radial-gradient(ellipse at center center, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 12%, rgba(255, 255, 255, 0.75) 37%, rgba(255, 255, 255, 0.5) 46%, rgba(255, 255, 255, 0) 70%) repeat scroll 0 0;
}

/* hix insurance banner text overrides */
.site-npis .banner .parallax-banner-insurance_retirement .slide-one .caption-centered > p {
  color: #4a4a4a;
}

.page-home .banner .slide-one h2,
.page-home .banner .slide-one p {
  text-shadow: 0 0 0 transparent !important;
}

.page-home .banner .slide-one .btn-default {
  margin-top: 20px;
}

.portal-bar {
  background: linear-gradient(to right, #eee 50%, #ddd 50%) repeat scroll 0 0 transparent;
}

.portal-bar nav {
  margin: 0;
}

.portal-bar nav span {
  display: inline-block;
  float: left;
  padding: 1em 0;
  text-align: center;
  width: 50%;
}

.wrapper > .feature > .container > div[class*="-content"] > .row > div:last-of-type h4 {
  margin-top: 0;
}

.about-content > .row > div {
  min-width: 100%;
}

@media only screen and (min-width: 1024px) {
  .about-content > .row > div {
    max-width: 50%;
    min-width: 50%;
  }
}

@media only screen and (min-width: 1400px) {
  .about-content > .row > div {
    max-width: 100%;
    min-width: 0%;
  }
}

.about-content img {
  margin: 0 0 40px;
  max-width: 100%;
}

@media only screen and (min-width: 1024px) {
  .about-content img {
    margin: 0 auto 20px;
  }
}

.about-content h4 {
  font-family: "Open Sans",sans-serif;
  font-weight: 300;
}

@media only screen and (min-width: 1024px) {
  .about-content h4 {
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 22px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 1400px) {
  .about-content h4 {
    font-size: 24px;
    line-height: 34px;
  }
}

.about-content p {
  font-size: 14px;
  line-height: 22px;
  margin: 0.9em 0 0 0;
  padding: 0;
}

@media only screen and (min-width: 1024px) {
  .about-content p {
    font-size: 15px;
    line-height: 24px;
    margin: 0.5em 0 0;
  }
}

@media screen and (min-width: 1000px) {
  .about-content p {
    font-size: 13px;
    line-height: 19px;
  }
}

@media screen and (min-width: 1200px) {
  .about-content p {
    font-size: 15px;
    line-height: 26px;
  }
}

.triple-swoosh-content {
  margin: 0 5%;
  width: 90%;
}

@media only screen and (min-width: 1024px) {
  .triple-swoosh-content {
    margin: 0;
    width: 100%;
  }
}

.triple-swoosh-content .row > div:last-of-type {
  text-align: center;
}

.triple-swoosh-content .row > div:last-of-type img {
  display: inline-block;
  margin: 3em 0 0;
  max-width: 375px;
}

#legal-expertise .legal-expertise-content > .row > div:first-of-type h4 {
  margin-top: 0;
}

#legal-expertise .legal-expertise-content > .row > div:last-of-type h4 {
  margin: 0 0 0.75em;
}

#legal-expertise .legal-expertise-content > .row > div:last-of-type ul {
  padding: 0 0 0 2em;
  font-size: 0.9em;
}

#legal-expertise .legal-expertise-content > .row > div:last-of-type li {
  margin: 0.75em 0;
  list-style-type: circle;
}

#legal-expertise .legal-expertise-content > .row > div:last-of-type img {
  width: auto;
  margin: 2em auto 0;
}

#legal-expertise .legal-expertise-content > .row > div:last-of-type p:last-of-type {
  font-size: 0.9em;
  vertical-align: middle;
  margin: 2em 1em;
  line-height: 1.75em;
  font-style: italic;
}

.logos-wc > ul {
  overflow: hidden;
  padding: 0;
  position: relative;
  margin: 2em 0 0 5%;
  width: 90%;
}

@media only screen and (min-width: 1400px) {
  .logos-wc > ul {
    width: 100%;
    margin: 0;
  }
}

.logos-insurance img {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 90px;
  height: auto;
  max-width: 80%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.logos-wc ul li {
  list-style-type: none;
  position: relative;
  height: 110px;
  text-align: center;
}

.logos-wc > ul > li[class*="col-sm-"] {
  display: inline-block;
  width: 32%;
}

@media only screen and (min-width: 500px) {
  .logos-wc > ul > li[class*="col-sm-"] {
    height: 100px;
    display: inline-block;
    width: 19%;
  }
}

@media only screen and (min-width: 767px) {
  .logos-wc > ul > li[class*="col-sm-"] {
    height: 150px;
  }
}

@media only screen and (min-width: 1024px) {
  .logos-wc > ul > li[class*="col-sm-"] {
    height: 175px;
    width: 20%;
  }
}

@media only screen and (min-width: 1024px) {
  .logos-insurance.logos-wc > ul > .col-sm-3 {
    width: 25%;
  }
}

.panels-blocks ul {
  font: 100 1.1em "PT Sans",sans-serif;
  list-style: outside none none;
  margin: 0 5%;
  overflow: hidden;
  padding: 0;
  width: 90%;
}

@media only screen and (min-width: 1400px) {
  .panels-blocks ul {
    padding: 0;
    width: 100%;
  }
}

.panels-blocks > ul > li {
  display: block;
  font-size: 13px;
  padding: 0;
  min-height: 75px;
}

@media only screen and (min-width: 400px) {
  .panels-blocks > ul > li {
    display: inline-block;
    max-width: 49%;
  }
}

@media only screen and (min-width: 767px) {
  .panels-blocks > ul > li {
    max-width: 100%;
  }
}

.panels-blocks.row > ul > li > span::before {
  content: url("/img/icons/list_checkbox.png");
  display: inline-block;
  margin-left: 1em;
  vertical-align: middle;
}

.panels-blocks li span {
  display: inline-block;
  margin: 2em 0 0;
  vertical-align: middle;
}

@media only screen and (min-width: 1024px) {
  .panels-blocks li span {
    margin: 1.5em 0 0.4em;
  }
}

/*
.panels-blocks {
    ul {
      list-style: outside none none;
      margin: 10px 0;
      padding: 0;
      width: 100%;
    }
  span {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: rgba(0, 0, 0, 0.07);
    border-color: rgba(0, 0, 0, 0.2);
    border-image: none;
    border-style: double none;
    border-width: 1px 0;
    color: #eee;
    font: 800 1.4em/1.4em "Pathway Gothic One";
    height: 1.6em;
    padding: 5px 0;
    letter-spacing: -1px;
    text-shadow: 1px 1px 0 #222;
    @include box-centered;
  }

  li {
    background-attachment: scroll;
    background-color: #556270;
    background-image: url("/nu/inc/bgAssets/diagonal-noise.png");
    background-origin: padding-box;
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto auto;
    border: 1.5px outset rgba(0, 0, 0, 0.5);
    margin: 2% 0 0 2%;
    min-height: 150px;
    width: 23%;
  }

  & .col-sm-2 {
    width: 14.5%;
  }

  ul > li:nth-of-type(1) {
    background-color: get-color(myColors, 0);
  }

  ul > li:nth-of-type(2) {
    background-color: get-color(myColors, 1);
  }

  ul > li:nth-of-type(3) {
    background-color: get-color(myColors, 2);
  }

  ul > li:nth-of-type(4) {
    background-color: get-color(myColors, 3);
  }

  ul > li:nth-of-type(5) {
    background-color: get-color(myColors, 4);
  }

  ul > li:nth-of-type(6) {
    background-color: get-color(myColors, 5);
  }

  ul > li:nth-of-type(7) {
    background-color: get-color(myColors, 6);
  }

  ul > li:nth-of-type(8) {
    background-color: get-color(myColors, 7);
  }

  ul > li:nth-of-type(9) {
    background-color: get-color(myColors, 8);
  }

  ul > li:nth-of-type(10) {
    background-color: get-color(myColors, 9);
  }

  ul > li:nth-of-type(11) {
    background-color: get-color(myColors, 10);
  }

  ul > li:nth-of-type(12) {
    background-color: get-color(myColors, 11);
  }

  ul > li:nth-of-type(13) {
    background-color: get-color(myColors, 12);
  }

  ul > li:nth-of-type(14) {
    background-color: get-color(myColors, 13);
  }

  ul > li:nth-of-type(15) {
    background-color: get-color(myColors, 14);
  }

  ul > li:nth-of-type(16) {
    background-color: get-color(myColors, 15);
  }

  & > .three.columns:first-of-type {
    & + .three.columns + .three.columns + .three.columns + .three.columns,
    & + .three.columns + .three.columns + .three.columns + .three.columns + .three.columns + .three.columns + .three.columns + .three.columns {
      margin-left: 0;
    }
  }
}
*/
.prudential-list {
  border-bottom: 2px solid #ddd;
  border-top: 2px solid #ddd;
  display: table;
  list-style: outside none none;
  margin: 6em 0 3em;
  padding: 0;
  text-align: center;
  width: 100%;
}

.prudential-list > li {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 100%;
  display: block;
  padding: 0.6em;
}

@media screen and (min-width: 800px) {
  .prudential-list > li {
    border-bottom: 0 none;
    width: 19%;
    display: table-cell;
  }
}

.v-wrap > img {
  display: block;
  height: auto;
  margin: 2em auto 3em;
  width: 100%;
  padding: 0;
}

@media screen and (min-width: 800px) {
  .v-wrap > img {
    display: inline-block;
    margin: 0 auto;
    width: 90%;
  }
}

.v-wrap > span {
  display: block;
  font: 400 16px/32px "PT Sans",sans-serif;
}

.prudential-content > .row > div {
  display: inline-block;
  float: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 767px) {
  .prudential-content > .row > div {
    width: 32.9%;
  }
  .prudential-content > .row > div div:nth-of-type(1) {
    text-align: left;
  }
}

.prudential-content > .row > div::before {
  content: "";
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 1%;
}

.v-wrap {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.prudential-content address {
  margin: 0;
  padding: 0;
  position: relative;
}

#career-opportunities .container .row .btn-default {
  margin: 2em 0 1em;
}

#form_message {
  display: none;
}

.npcap > div > div,
.npcap > div > div > iframe {
  width: 100%;
}

@media only screen and (min-width: 767px) {
  .npcap > div > div,
  .npcap > div > div > iframe {
    width: calc();
  }
}

.rc-anchor {
  max-width: 95%;
}

@media only screen and (min-width: 500px) {
  .rc-anchor {
    max-width: 100%;
  }
}

#formFly .form-submit .btn-theme {
  margin-top: 6px;
}

#contact-our-company .npcap {
  display: inline-block;
  float: left;
}

#contact-our-company .form-message {
  max-width: 100%;
  padding-left: 52%;
  text-align: right;
}

.form-group {
  margin-bottom: 19px;
}

.form-submit {
  text-align: right;
}

#contact_message {
  height: 170px;
  margin-bottom: 10px;
}

.np-form .error {
  color: red;
  font-style: italic;
}

.np-form .npcap {
  background-clip: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 10px 0;
  height: 82px;
  width: 309px;
  padding: 2px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.gcap {
  position: relative;
  z-index: 2;
}

.npcap > a {
  cursor: pointer;
  display: inline-block;
  left: 0;
  line-height: 1.5em;
  min-height: 2em;
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.npcap > input[name="form_message"] {
  display: none;
}

.privacy-content .row {
  display: inline-block;
  margin: 0 10%;
  width: 80%;
  min-width: 80%;
}

@media only screen and (min-width: 767px) {
  .privacy-content .row {
    margin: 0 5%;
    width: 90%;
    min-width: 90%;
  }
}

.txtPage > div {
  margin: 30px 0;
}

@media only screen and (min-width: 1024px) {
  .txtPage > div {
    display: inline-block;
    margin: 0 4% 0 0;
    vertical-align: top;
    width: 45%;
  }
}

.txtPage > div > a:first-of-type {
  border-bottom: 1px solid;
  display: block;
  font-size: 18px;
  margin: 2em 0 0.75em;
}

.totop a,
.totop a:visited {
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 30px;
  min-height: 50px;
  line-height: 25px;
  padding-top: 8px;
  text-align: center;
  width: 50px;
}

.totop a:hover {
  color: #fff;
  text-shadow: 1px 1px 0 #333;
  padding-top: 5px;
}

#content_overlay {
  display: none;
  background-color: rgba(0, 6, 0, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 50;
}

#formFly {
  background: none repeat scroll 0 0 #fff;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  bottom: 60px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  height: auto;
  left: -80%;
  position: fixed;
  top: 65px;
  width: 80%;
  z-index: 500;
}

@media only screen and (min-width: 500px) {
  #formFly {
    top: 125px;
    left: -360px;
    width: 360px;
  }
}

#formFly button {
  border-color: #CCC;
}

#formFly .np-form {
  height: 100%;
  overflow: auto;
  padding: 0 2%;
  position: absolute;
  width: 100%;
}

#formFly .row {
  margin: 0;
  padding: 13px 10px;
}

.form-group > label {
  display: none;
}

#formFly .row > .form-job:first-of-type {
  border-bottom: 3px solid green !important;
}

.form-text > span {
  border-bottom: 1px solid #ccc;
  margin: 25px 0 11px;
  padding-bottom: 4px;
  display: block;
}

.form-text > .txt_title {
  border-bottom: 0 none;
  font-size: 20px;
  line-height: 20px;
  padding-bottom: 0;
}

/* Home and Renters Override */
.site-npis .form-text > .txt_title,
.site-haris .form-text > .txt_title {
  font-size: 17px;
}

#formFly .row > .form-text:first-of-type > span {
  margin-top: 0;
}

#formFly .form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555;
  display: block;
  font-size: 12px;
  height: 28px;
  line-height: 1.42857;
  padding: 4px 8px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

#formFly input,
#formFly input:focus,
#formFly textarea,
#formFly textarea:focus {
  font-size: 13px !important;
}

#formFly .form-group {
  margin-bottom: 10px;
}

#formFly [name="contact_message"] {
  height: 100px;
  max-width: 300px;
  min-width: 100%;
  margin-top: 5px;
}

#formFly .row > .discussion-topics > .form-check,
#formFly .row > .topic-options > .hr > .form-hr,
#formFly .row > .topic-options > .support > .form-support,
#formFly .row > .topic-options > .careers > .form-job,
#formFly .row > .topic-options > .insurance > .form-ins {
  display: inline-block;
  margin: 0 0 1%;
  text-align: left !important;
  vertical-align: middle;
  width: 100%;
}

@media only screen and (min-width: 500px) {
  #formFly .row > .discussion-topics > .form-check,
  #formFly .row > .topic-options > .hr > .form-hr,
  #formFly .row > .topic-options > .support > .form-support,
  #formFly .row > .topic-options > .careers > .form-job,
  #formFly .row > .topic-options > .insurance > .form-ins {
    width: 32%;
  }
}

#formFly .row > .discussion-topics > .form-check button,
#formFly .row > .topic-options > .hr > .form-hr button,
#formFly .row > .topic-options > .support > .form-support button,
#formFly .row > .topic-options > .careers > .form-job button,
#formFly .row > .topic-options > .insurance > .form-ins button {
  font-size: 10px;
  padding: 3px 0;
  vertical-align: top;
  width: 100%;
}

#formFly .style-btn {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 #fff;
  border-bottom-right-radius: 3px;
  border-color: #ccc #ccc #ccc -moz-use-text-color;
  border-image: none;
  border-style: solid solid solid none;
  border-top-right-radius: 3px;
  border-width: 1px 1px 1px 0;
  color: #3f910d;
  cursor: pointer;
  font-size: 25px;
  height: 40px;
  left: 100%;
  line-height: 32px;
  margin-left: 0;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 40px;
  z-index: 501;
}

#formFly .style-btn:hover {
  color: #59ae26;
}

#formFly .style-btn > i {
  line-height: 35px;
  padding-right: 2px;
}

#formFly .row > .form-job,
#formFly .row > .form-ins {
  width: 49%;
}

#formFly .row > .form-job > *,
#formFly .row > .form-ins > * {
  width: 100%;
}

.sitemapDisplay {
  padding: 0;
}

.sitemapDisplay * {
  list-style: outside none none;
}

.sitemap h2 {
  margin-bottom: 1em;
  text-align: center;
}

.sitemapDisplay .sitemapSub {
  padding: 0;
}

.sitemapyDisplay .dropdown-menu,
.sitemapyDisplay .sitemapPages {
  box-shadow: 0 0 0;
  float: none;
  display: inline-block;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 0;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  font-size: 14px;
  left: 0;
  list-style: outside none none;
  margin: 2px 0 4em;
  min-width: 160px;
  text-align: left;
}

.sitemapDisplay .dropdown-menu {
  display: block;
  box-shadow: 0 0 0;
  float: none;
  border: 0 none transparent;
  background: transparent;
  position: relative;
  border-radius: 0;
  z-index: 0;
}

.sitemapDisplay .sitemapTitle {
  width: 100%;
  text-align: center;
}

.sitemapDisplay .sitemapTitle a img {
  display: none;
}

.sitemapDisplay .sitemapHeading,
.sitemapPages .dropdown-toggle,
.sitemapPages > li > .page-scroll {
  border-bottom: 3px double #cccccc;
  display: inline-block;
  font-size: 1.4em;
  padding: 4px 10px;
  width: 100%;
}

.sitemapDisplay .sitemapHeading::after,
.sitemapPages .dropdown-toggle::after,
.sitemapPages > li > .page-scroll::after {
  content: url("../img/icons/external2.png");
  float: right;
}

.sitemapSub > li:nth-of-type(2n),
.sitemapDisplay .sitemapPages > li > ul > li:nth-of-type(2n) {
  background-color: rgba(0, 0, 0, 0.06);
}

.sitemapDisplay .sitemapSub > li,
.sitemapDisplay .sitemapPages > li > ul > li {
  color: #acacac;
  display: list-item;
  list-style: inside none square;
  padding: 0.6em 0 0.6em 0.8em;
}

.sitemapPages li a {
  display: inline-block;
}

.sitemapSection,
.sitemapPages > li {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  margin: 0 0 4em;
  border-radius: 0;
}

.sitemapPages {
  padding: 0;
}

.sitemapSection li a,
.sitemapPages li li a {
  color: #3f910d;
  display: inline-block;
  padding-left: 6px;
}

.sitemapPages li li a:hover {
  background: none repeat scroll 0 0 transparent;
  color: #59ae26;
}

[class*="animation"] {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

[class*="animated"] {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.delay-1 {
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -ms-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.delay-2 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-3 {
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.delay-4 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}

.delay-5 {
  -webkit-animation-delay: 1.25s;
  -moz-animation-delay: 1.25s;
  -ms-animation-delay: 1.25s;
  -o-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

.delay-6 {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.delay-7 {
  -webkit-animation-delay: 1.75s;
  -moz-animation-delay: 1.75s;
  -ms-animation-delay: 1.75s;
  -o-animation-delay: 1.75s;
  animation-delay: 1.75s;
}

.delay-8 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -ms-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}

.delay-9 {
  -webkit-animation-delay: 2.25s;
  -moz-animation-delay: 2.25s;
  -ms-animation-delay: 2.25s;
  -o-animation-delay: 2.25s;
  animation-delay: 2.25s;
}

.delay-10 {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.delay-11 {
  -webkit-animation-delay: 2.75s;
  -moz-animation-delay: 2.75s;
  -ms-animation-delay: 2.75s;
  -o-animation-delay: 2.75s;
  animation-delay: 2.75s;
}

.delay-12 {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -ms-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;
}

.delay-13 {
  -webkit-animation-delay: 3.25s;
  -moz-animation-delay: 3.25s;
  -ms-animation-delay: 3.25s;
  -o-animation-delay: 3.25s;
  animation-delay: 3.25s;
}

.delay-14 {
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
  -ms-animation-delay: 3.5s;
  -o-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

.delay-15 {
  -webkit-animation-delay: 3.75s;
  -moz-animation-delay: 3.75s;
  -ms-animation-delay: 3.75s;
  -o-animation-delay: 3.75s;
  animation-delay: 3.75s;
}

.delay-16 {
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -ms-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
}

.delay-17 {
  -webkit-animation-delay: 4.25s;
  -moz-animation-delay: 4.25s;
  -ms-animation-delay: 4.25s;
  -o-animation-delay: 4.25s;
  animation-delay: 4.25s;
}

.delay-18 {
  -webkit-animation-delay: 4.5s;
  -moz-animation-delay: 4.5s;
  -ms-animation-delay: 4.5s;
  -o-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

.delay-19 {
  -webkit-animation-delay: 4.75s;
  -moz-animation-delay: 4.75s;
  -ms-animation-delay: 4.75s;
  -o-animation-delay: 4.75s;
  animation-delay: 4.75s;
}

.delay-20 {
  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5s;
  -ms-animation-delay: 5s;
  -o-animation-delay: 5s;
  animation-delay: 5s;
}

.duration-1 {
  -webkit-animation-duration: 0.25s;
  -moz-animation-duration: 0.25s;
  -ms-animation-duration: 0.25s;
  -o-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.duration-2 {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.duration-3 {
  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  -ms-animation-duration: 0.75s;
  -o-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

.duration-4 {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}

.duration-5 {
  -webkit-animation-duration: 1.25s;
  -moz-animation-duration: 1.25s;
  -ms-animation-duration: 1.25s;
  -o-animation-duration: 1.25s;
  animation-duration: 1.25s;
}

.duration-6 {
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

.duration-7 {
  -webkit-animation-duration: 1.75s;
  -moz-animation-duration: 1.75s;
  -ms-animation-duration: 1.75s;
  -o-animation-duration: 1.75s;
  animation-duration: 1.75s;
}

.duration-8 {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
}

.duration-9 {
  -webkit-animation-duration: 2.25s;
  -moz-animation-duration: 2.25s;
  -ms-animation-duration: 2.25s;
  -o-animation-duration: 2.25s;
  animation-duration: 2.25s;
}

.duration-10 {
  -webkit-animation-duration: 2.5s;
  -moz-animation-duration: 2.5s;
  -ms-animation-duration: 2.5s;
  -o-animation-duration: 2.5s;
  animation-duration: 2.5s;
}

.duration-11 {
  -webkit-animation-duration: 2.75s;
  -moz-animation-duration: 2.75s;
  -ms-animation-duration: 2.75s;
  -o-animation-duration: 2.75s;
  animation-duration: 2.75s;
}

.duration-12 {
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -ms-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s;
}

.duration-13 {
  -webkit-animation-duration: 3.25s;
  -moz-animation-duration: 3.25s;
  -ms-animation-duration: 3.25s;
  -o-animation-duration: 3.25s;
  animation-duration: 3.25s;
}

.duration-14 {
  -webkit-animation-duration: 3.5s;
  -moz-animation-duration: 3.5s;
  -ms-animation-duration: 3.5s;
  -o-animation-duration: 3.5s;
  animation-duration: 3.5s;
}

.duration-15 {
  -webkit-animation-duration: 3.75s;
  -moz-animation-duration: 3.75s;
  -ms-animation-duration: 3.75s;
  -o-animation-duration: 3.75s;
  animation-duration: 3.75s;
}

.duration-16 {
  -webkit-animation-duration: 4s;
  -moz-animation-duration: 4s;
  -ms-animation-duration: 4s;
  -o-animation-duration: 4s;
  animation-duration: 4s;
}

.duration-17 {
  -webkit-animation-duration: 4.25s;
  -moz-animation-duration: 4.25s;
  -ms-animation-duration: 4.25s;
  -o-animation-duration: 4.25s;
  animation-duration: 4.25s;
}

.duration-18 {
  -webkit-animation-duration: 4.5s;
  -moz-animation-duration: 4.5s;
  -ms-animation-duration: 4.5s;
  -o-animation-duration: 4.5s;
  animation-duration: 4.5s;
}

.duration-19 {
  -webkit-animation-duration: 4.75s;
  -moz-animation-duration: 4.75s;
  -ms-animation-duration: 4.75s;
  -o-animation-duration: 4.75s;
  animation-duration: 4.75s;
}

.duration-20 {
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -ms-animation-duration: 5s;
  -o-animation-duration: 5s;
  animation-duration: 5s;
}

/*
.feature .feature-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation-duration: 1.25s;
    -moz-animation-duration: 1.25s;
    -ms-animation-duration: 1.25s;
    -o-animation-duration: 1.25s;
    animation-duration: 1.25s;
}

.feature .feature-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}


.feature .feature-item a i {
    display: inline-block;
    width: 110px;
    height: 110px;
    line-height: 102px;
    font-size: 40px;
    color: #ddd;
    border: 5px solid #ddd;
    border-radius: 150px;
    box-shadow: inset 0 0 1px 1px #fff, 0 0 1px 4px #fff;
    -webkit-transition: all 0.35s linear;
    -moz-transition: all 0.35s linear;
    -ms-transition: all 0.35s linear;
    -o-transition: all 0.35s linear;
    transition: all 0.35s linear;
}

*/
/* this section is for the get quote button */
.getQuoteButton {
  display: none;
}

@media only screen and (min-width: 767px) {
  .getQuoteButton {
    border-radius: 2px;
    margin: 25px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 5;
  }
}

.getQuoteButton span, .getQuoteButton i {
  display: inline-block;
}

.getQuoteButton > a > i {
  padding-left: 6px;
}

/*  Error Page */
.error-page p {
  font-size: 16px;
}

/*  INSURANCE SITE */
/*  hide heading icons on insurance website */
@media only screen and (min-width: 767px) {
  .site-npis .navbar-default .navbar-nav li a {
    font-size: 9px;
    padding: 30px 6px;
  }
}

@media screen and (min-width: 900px) {
  .site-npis .navbar-default .navbar-nav li a {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .site-npis .navbar-default .navbar-nav li a {
    font-size: 11px;
    padding: 30px 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .site-npis .navbar-default .navbar-nav li a {
    font-size: 13px;
    padding: 30px 10px;
  }
}

@media only screen and (min-width: 1400px) {
  .site-npis .navbar-default .navbar-nav li a {
    font-size: 14px;
    padding: 30px 16px;
  }
}

.site-npis .navbar-default .navbar-nav > li {
  text-align: left;
}

@media screen and (min-width: 900px) {
  .site-npis .navbar-default .navbar-nav > li {
    min-width: 50px;
    text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  .site-npis .navbar-default .navbar-nav > li {
    min-width: 60px;
  }
}

@media only screen and (min-width: 1200px) {
  .site-npis .navbar-default .navbar-nav > li {
    min-width: 75px;
  }
}

.site-npis .navbar-default .navbar-nav > li a {
  margin-bottom: -1px;
}

@media only screen and (min-width: 1024px) {
  .site-npis.page-home .banner .slide-one h2 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 1400px) {
  .site-npis.page-home .banner .slide-one h2 {
    font-size: 74px;
  }
}

.site-npis .container .default-header i {
  display: none;
}

.site-npis .contact {
  background-color: #FAFAFA;
}

.site-npis.page-home .portfolio-content .p-element {
  width: 19.97%;
}

.site-npis .portfolio #filters {
  margin-bottom: 75px;
}

.about-content {
  max-width: 100%;
}

/* Resources page */
#netpay-reports-viewer .about-content .row div img {
  margin: 40px 0 0;
}

@media only screen and (min-width: 1024px) {
  #netpay-reports-viewer .about-content .row div img {
    margin: 0;
  }
}

/* Calculator Button on Resources page */
.calc-button {
  display: none;
}

.parallax-banner-resources .calc-button {
  background-color: #fff;
  border-radius: 3px;
  padding: 0.5em 1em;
  position: absolute;
  right: 20px;
  top: 20px;
}

@media only screen and (min-width: 767px) {
  .parallax-banner-resources .calc-button {
    display: block;
  }
}

.parallax-banner-resources .calc-button i {
  border-left: 1px solid #ccc;
  color: #59ae26;
  margin-left: 8px;
  padding-left: 8px;
}
