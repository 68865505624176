/**
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
 * MIXINS
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@mixin phone {
  @media only screen and (min-width: $phone-width) {
    @content;
  }
}

@mixin small-tablet {
  @media only screen and (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $tablet-width) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktop-width) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: $laptop-width) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: $large-width) {
    @content;
  }
}

@mixin mh300 {
  @media only screen and (min-height: 300px) and (min-width: $tablet-width) {
    @content;
  }
}

@mixin mh400 {
  @media only screen and (min-height: 400px) and (min-width: $tablet-width) {
    @content;
  }
}

@mixin mh500 {
  @media only screen and (min-height: 500px) and (min-width: $tablet-width) {
    @content;
  }
}

@mixin mh600 {
  @media only screen and (min-height: 600px) and (min-width: $tablet-width) {
    @content;
  }
}

@mixin mh700 {
  @media only screen and (min-height: 700px) and (min-width: $tablet-width) {
    @content;
  }
}

@mixin mh800 {
  @media only screen and (min-height: 800px) and (min-width: $tablet-width) {
    @content;
  }
}

@mixin mh900 {
  @media only screen and (min-height: 900px) and (min-width: $tablet-width) {
    @content;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}



// animation classes

$columns: 21;

@mixin delay-x-list {
  @for $i from 1 through $columns - 1 {
    .delay-#{$i} {
      -webkit-animation-delay: #{$i * 0.25}s;
      -moz-animation-delay: #{$i * 0.25}s;
      -ms-animation-delay: #{$i * 0.25}s;
      -o-animation-delay: #{$i * 0.25}s;
      animation-delay: #{$i * 0.25}s;
    }
  }
}

@mixin duration-x-list {
  @for $i from 1 through $columns - 1 {
    .duration-#{$i} {
      -webkit-animation-duration: #{$i * 0.25}s;
      -moz-animation-duration: #{$i * 0.25}s;
      -ms-animation-duration: #{$i * 0.25}s;
      -o-animation-duration: #{$i * 0.25}s;
      animation-duration: #{$i * 0.25}s;
    }
  }
}




// futile attempts

@mixin ghost-align {
  position: relative;
  &::before {
    content: " ";
    display: inline-block;
    height: 100%;
    width: 1%;
    vertical-align: middle;
  }
}

@mixin box-centered {
  position: absolute;
  text-align: center;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;;
}

@mixin ghost-centered {
  text-align: center;
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
  }

  & > * {
    display: inline-block;
    vertical-align: middle;
    width: 300px;
  }
}

%center-vertical {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  // hack for iOS
  vertical-align: top;
}

%flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}


@mixin bg-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // IOS has a bug with fixed, this works around that -- be careful if you turn parallax back on, the BG will be off
    @media only screen and (-webkit-min-device-pixel-ratio: 1) , screen and (-webkit-min-device-pixel-ratio: 2) {
      background-attachment: fixed;
    }
}

@mixin vendorize($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}