/* CUSTOM */

// body padding

body {
  padding-top: 50px;
  @include tablet {
    padding-top: 80px;
  }
}

.row {
  min-width: 100%;
}

// header

.header {
  max-height: 100%;
  overflow-y: visible;
}

.navbar-header {
  overflow: hidden;
}


// logo area in header

.navbar-brand {
  float: left;
  font-size: 18px;
  height: 50px;
  @include tablet {
    height: 70px;
  }
  line-height: 20px;
  padding: 15px;
}

.navbar-header .navbar-brand {
  padding-top: 2px;
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
    margin: 0;
    padding: 0 0 0 7px;
}

.navbar-header .navbar-brand img {
  max-width: 200px;
  padding: 6px 0 2px 22px;
  width: 100%;
  @include tablet {
    max-width: 250px;
    padding: 15px 0 0 5px;
  }
  @media (min-width: 900px) {
    max-width: 300px;
    padding: 10px 0 10px 10px;
  }
}


// overrides for compressed menu

.header-compress {
    .navbar-header .navbar-brand img {
      max-width: 200px;
      padding: 6px 0 0px 15px;
      width: 100%;
      @include tablet {
        max-width: 250px;
        padding: 8px 0 0 8px;
      }
    }
    .navbar-default .navbar-nav > li > a {
      padding: 24px 40px !important;
      @include small-tablet {
        padding: 25px 9px !important;
      }
      @include desktop {
        padding: 25px 20px !important;
      }
    }
    .navbar-header .navbar-nav li .nav-page {
      padding: 21px 15px !important;
    }
}

// override for npis header logo
.site-npis .header-compress .navbar-header .navbar-brand img {
  @include tablet {
    max-width: 270px;
    padding: 7px 0 0 0;
  }
}



// navbar dropdown toggle

.navbar-default .navbar-nav > .current > a {
  color: $theme-hlink;
  font-size: 16px;
  font-weight: 700;
}

.navbar-toggle {
  margin-right: 23px;
  padding: 9px 8px;
}


// navbar items

// top row only
.site-nppr .navbar-default .navbar-nav > li {
  text-align: left;
  @include tablet {
    min-width: 48px;
    text-align: center;
  }
  @media screen and (min-width: 850px) {
    min-width: 64px;
  }
  a {
    margin-bottom: -1px;
  }
}

.navbar-default .navbar-nav li {
  .nav-page {
    color: $theme-color;
    font-size: 17px;
    font-weight: 800;
    line-height: 20px;
    padding: 10px 25px;
    border-bottom: 3px double;
    @include tablet {
        padding: 30px 8px;
        border-bottom: 0 none rgba(0,0,0,0);
    }
    @include desktop {
        padding: 30px 15px;
      }
  }
  .nav-active {
      text-decoration: underline;
  }
    a {
        font-size: 17px;
        font-weight: 500;
        padding: 10px 35px;
        border-bottom: 1px groove rgba(0,0,0,0.2);
      @include tablet {
        font-size: 9px;
        padding: 30px 8px;
        border-bottom: 0 none rgba(0,0,0,0);
      }
      @include desktop {
        font-size: 12px;
        padding: 30px 15px;
      }
    }

}

// drop down menu

.navbar-default .nav .dropdown-menu {
  border-top: 1px solid #ddd;
  top: 102%;
}

.navbar-default .nav .open .dropdown-menu > li > a {
  background: none repeat scroll 0 0 #ececec;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  padding: 10px 50px;
  @include tablet {
    background: none repeat scroll 0 0 #fff;
    border-bottom: 1px solid;
    color: #898989;
    padding: 10px 15px;
  }
}


.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: $theme-hlink;
    background-color: #DDD;
}

.navbar-nav li a .btn {
  background-color: rgba(0, 0, 0, 0);
  color: #999 !important;
  font-size: 16px;
  padding: 0;
  @include tablet {
    color: #fff !important;
    background-color: $green;
    padding: 6px 12px;
  }
}

// override for last menu alignment to keep on screen

.navbar-nav > li:nth-of-type(6) ul {
  @include tablet {
    margin-left: -65px;
    right: 0;
  }
}


// header login button

.navbar-nav li .btn-theme {
  margin: -8px 0;
}


// first sub item (link to page)

.navbar .navbar-nav .dropdown-menu > li:first-of-type a {
  background-color: #eee;
  font-weight: 800;
}











// Sections

// shared classes for sections

.pad {
  padding: 40px 0px;
  @include tablet {
    padding: 60px 0px;
  }
  @include large {
    padding: 90px 0px;
  }
}

.container .default-header {
  margin: 0 0 70px;
  padding: 0;
}


.container .default-header h3 {
  font-size: 30px;
  margin: 40px 0;
  @include tablet {
    font-size: 50px;
  }
}

.container .default-header p {
  font-size: 14px;
  letter-spacing: 0.036em;
  line-height: 30px;
  @include tablet {
    font-size: 18px;
  }
}

// CTAs

.cta .cta-content h4 {
  color: #fff;
  font-size: 30px;
  margin: 0 0 10px;
  @include tablet {
    font-size: 45px;
  }
}

.cta .cta-content .row > div {
  margin: 0 5%;
  min-width: 90%;
  @include desktop {
    margin: 0;
    min-width: 0;
  }
}



// videos section

.video {
  padding-bottom: 70px;
  padding-top: 70px;
}

.video-content > .row > div {
  width: 100%;
  @include desktop {
    width: 50%;
  }
}

.video .video-container {
  margin: 0 auto;
}

.video .video-caption {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.36);
  box-shadow: -2px -2px 10px -3px rgba(0, 0, 0, 0.5) inset;
  margin-bottom: 5px;
}

.video-caption img {
  display: none;
  height: 82px;
  @include small-tablet {
    display: inline-block;
  }
  @include laptop {
    height: auto;
  }
}

.video .video-caption h3 {
  display: inline-block;
  margin: 0;
  padding: 2rem;
  vertical-align: middle;
  font-size: 20px;
  @include desktop {
    font-size: 24px;
  }
}



// testimonials section

.testimonial {
    .carousel-inner {
      padding-bottom: 10px;
      min-height: 150px;
    }

    .carousel .carousel-control {
      bottom: 6px;
      top: auto;
      margin: auto;
    }

    .carousel .carousel-control.left {
      left: 50%;
      margin-left: -70px;
    }

    .carousel .carousel-control.right {
      right: 50%;
      margin-right: -70px;
    }

    .testimonial-content p {
      font-style: italic;
      letter-spacing: 0.5px;
    }

    .testimonial-content p:before,
    .testimonial-content p:after {
      font-size: 11px;
      padding: 0 1em;
      top: -7px;
    }

    .item h3 {
      font-weight: 100;

      a {
        font-weight: 700;
      }

      span {
        display: block;
        @include tablet {
          display: inline-block;
        }
      }

    }


    .testimonial-content i {
        font-size: 40px;
        color: #434343;
    }
}


// footer section
.footer {
    background: url("../img/textures/bg_black-orchid.png") repeat scroll 0 0 #1f3e56;
}

// company col
.footer-widget .footer-post li {
  border-bottom: 1px dashed #777;
}

.footer-widget .footer-post img {
  display: none;
  @include tablet {
    border: 0 none rgba(0,0,0,0);
    max-width: 100px;
  }
  @include desktop {
    display: block;
  }
}

.footer-widget .footer-post .title,
.footer-widget .footer-post p {
  line-height: 20px;
  margin-left: 0;
  @include tablet {
    line-height: 38px;
  }
  @include desktop {
    margin-left: 112px;
  }
}

.footer-widget .footer-post .title {
  font-size: 14px;
  @include tablet {
    font-size: 18px;
  }
}

// contact page

.form-content {
  margin-top: 60px;
  padding-top: 0;
}


// contact section

.footer-widget.footer-contact span {
  line-height: 25px;
}

.footer-widget.footer-contact strong {
  color: #3f910d;
  display: block;
  font-size: 18px;
  margin-bottom: 6px;
}

.site-npis .footer-widget.footer-contact strong {
  font-size: 16px;
}

.footer-widget.footer-contact {
    .fa-phone {
        margin-top: 10px
    }
    .fa-fax {
        margin-bottom: 10px
    }
}

.footer-widget.footer-contact .social i {
  margin-right: 10px;
  font-size: 18px;
  &.fa-envelope-o {
    color: #8F8F8D;
  }
  &.fa-linkedin {
    color: $linkedin;
  }
  &.fa-yelp {
    color: $yelp;
  }
  &.fa-facebook {
    color: $facebook;
  }
  &.fa-twitter {
    color: $twitter;
  }
}

.footer-content {
  max-width: 90%;
}
@media screen and (min-width: 768px) {
  .footer-content {
    max-width: 100%;
  }
}

.footer .pull-right,
.footer .pull-left {
  line-height: 20px;
}


// portfolio aka

.portfolio {
  padding-top: 50px;
}

.portfolio #filters {
  margin-bottom: 55px;
}

.portfolio-content .p-element h3 {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
  font-size: 24px;
}

.portfolio-content .p-hover span {
  background-color: $theme-color;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  height: 45px;
  left: 50%;
  line-height: 45px;
  margin-left: -60px;
  margin-top: -20px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 120px;
  z-index: 15;
}

.portfolio-content li .btn {
  width: 90%;
  margin: 0 0 0 5%;
  @include small-tablet {
    width: auto;
    margin: inherit;
  }
}




// payroll process



.below-process {
  font-size: 1.2em;
  font-style: oblique;
  color: $dark-green;
  & > p {
    padding: 0 1em;
  }
  & > div {
    display: none;
    @media screen and (min-width: 993px) {
      display: inline-block;
      max-width: 100%;
      min-width: 0%;
    }
  }
  .process-info {
    margin: -15px auto 20px;
    text-align: center;
    &::after {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      border-color: $green $green transparent;
      border-image: none;
      border-style: solid solid none;
      border-width: 1px 1px 0;
      content: "";
      display: block;
      height: 1em;
      margin-top: 0.5em;
      width: 100%;
    }
    div > span {
      display: inline-block;
      height: 25px;
      margin: 0 15%;
      padding: 14px 0 5px;
      text-align: center;
      width: 70%;
    }
  }
}

.process-content .process-item {
  min-height: 320px;
  & > p {
    margin: 0.7em;
  }
  i {
    color: $green;
  }
  i:hover {
    color: $dark-green;
  }
}

.process-item.process-active {
  //border: 2px solid #59ae26;
  //border-bottom: 5px solid #59ae26;
  background: url("../img/textures/bg_cream-pixels.png") repeat scroll 0 0 #f4f8f0;
}


// home features

#netpay-advantage img {
  display: inline-block;
  width: 85%;
  height: auto;
}


// get started

.getstarted-content > div > div > div::after {
  bottom: 0;
  color: #ddd;
  content: "›";
  display: inline-block;
  font-size: 140px;
  height: 0;
  line-height: 0;
  margin: auto;
  position: absolute;
  right: 0.2em;
  text-shadow: 0.15em 0 0 #ddd;
  top: 0;
  vertical-align: top;
  width: 0;
  z-index: 9;
}

.pricing .getstarted-content .getstarted-item {
    text-align: center;
    background: #fefefe;
    box-shadow: inset 0 0 0 1px #ddd;
    border-radius: 4px;
}

.getstarted-content .getstarted-item h3 {
    margin: 0;
    padding: 30px 0;
    font-size: 32px;
    background: #eee;
    box-shadow: inset 0 0 0 1px #ddd;
}

.getstarted-content .getstarted-item h4 {
    margin-top: 25px;
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
}

.getstarted-content .getstarted-item .sub-text {
    display: block;
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
    font-style: italic;
    border-bottom: 1px dashed #ddd;
}

.getstarted-content .getstarted-item h4 sub {
    position: relative;
    font-size: 20px;
    left: 3px;
    top: 0px;
}

.getstarted-content .getstarted-item .getstarted-details {
    margin: 15px;
    background: #fff;
    border: 1px solid #fff;
}

.getstarted-content .getstarted-item .getstarted-details ul {margin: 0;}
.getstarted-content .getstarted-item .getstarted-details ul li {
    padding: 9px 12px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    border-bottom: 1px solid #fff;
}

.getstarted-content .getstarted-item .getstarted-details ul li:last-child {border-bottom:0;}
.getstarted-content .getstarted-item .getstarted-details ul li:nth-child(odd){background: #edf5f7;}
.getstarted-content .getstarted-item .getstarted-details ul li:nth-child(even){background: #f3fbfe;}
.getstarted-content .getstarted-item .getstarted-details ul li i {
    margin-right: 6px;
    width: 18px;
    font-size: 18px;
}

.getstarted-content .getstarted-item .btn-area {
    padding: 20px 15px;
    border-top: 1px dashed #ddd;
}

.getstarted-content .getstarted-item .btn-area .btn {
    display: block;
    padding: 10px 0;
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;
}

.getstarted-content .getstarted-item.highlight {
    position: absolute;
    left: 0;
    top: -9px;
    width: 100%;
    box-shadow: inset 0 0 0 1px #ddd, 0 1px 20px #ccc;
}

.getstarted-content .getstarted-item.highlight h3 {
  font-size: 40px;
  color: #fff;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  background: $green;
}

.getstarted-content .getstarted-item.highlight h4 {
  font-size: 32px;
}
.getstarted-content .getstarted-item.highlight .sub-text {
  font-size: 16px;
  padding-bottom: 16px;
}
.getstarted-content .getstarted-item.highlight .btn-area .btn {
  padding: 15px 0;
}


.getstarted-content > div > div:last-of-type > div::after {
  display: none;
}

.getstarted-item span {
  margin-top: 1em;
  display: inline-block;
}


.getstarted-content .getstarted-item.highlight {
  top: 0;
  position: relative;
  h3 {
    font-size: 32px;
  }

  .btn-area .btn {
    padding: 10px 0;
  }
}




// payroll features

.service .service-item {
  min-height: 230px;
}

.page-resources .service .service-item {
  min-height: 150px;
}

.service .service-item p {
  margin-top: 15px;
  font-size: 13px;
  color: $black;
}

#payroll-features .panels-blocks li span {
  font-size: 12px;
}

// netpay green

.netpay-green {
  background: url("../img/textures/overlay-pattern.png") repeat scroll 0 0%, url("../img/bgs/4.jpg") no-repeat fixed 50% center / cover  rgba(0, 0, 0, 0);
  @include bg-cover;
    & * {
        color: #fff;
        text-shadow: 1px 1px 0 #333;
    }
    h3 {
      font-size: 30px;
      margin: 0 0 10px;
    }
    h4 {
      font-family: "Open Sans",sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    img {
       max-width: 90%;
    }
}

.netpay-green-content > div > div > ul {
  list-style: outside none none;
  padding: 0 0 0 1em;
  li {
      font-size: 16px;
      font-weight: 600;
      padding: 0.5em 0;
    i {
      font-size: 12px;
      line-height: 0;
      padding-right: 0.5em;
    }
  }
}




// to be used by all sections with a darkened bg

.bg-dark {
  background: none repeat scroll 0 0 #f8f8f8;
  border-top: 1px solid #e5e5e5;
  box-shadow: 0 0 6px #e1e1e1 inset;
}


// video description

.video-description {
    & h3, & h4, & p {
        color: white;
    }
    h3 {
        margin-top: 40px;
        @include desktop {
          margin-top: 0;
        }
    }
    h4 {
        margin-top: 0;
    }
    p {

    }
}

// banner

.banner {
  position: relative;
  overflow: hidden;
}


.overlay-pattern {
  background: url('../img/textures/overlay-pattern.png') repeat;
}
.parallax-cta {
  background: url('../img/textures/overlay-pattern.png'), url('../img/bgs/1.jpg') 50% fixed;
  overflow: hidden;
}
.parallax-cta ~ .parallax-cta {
  background: url('../img/textures/overlay-pattern.png'), url('../img/bgs/2.jpg') 50% fixed;
}
.parallax-cta ~ .parallax-cta ~ .parallax-cta {
  background: url('../img/textures/overlay-pattern.png'), url('../img/bgs/3.jpg') 50% fixed;
}
.parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta  {
  background: url('../img/textures/overlay-pattern.png'), url('../img/bgs/4.jpg') 50% fixed;
}
.parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta {
  background: url('../img/textures/overlay-pattern.png'), url('../img/bgs/5.jpg') 50% fixed;
}
.parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta ~ .parallax-cta {
  background: url('../img/textures/overlay-pattern.png'), url('../img/bgs/6.jpg') 50% fixed;
}


div[class*="parallax-banner"] {
  height: 300px;
  position: relative;
  background-size: 125% 540px;

  @include small-tablet {
  background-size: cover;
  @include bg-cover;
  }

  @include tablet {
  height: 350px;
  }

  @include desktop {
    font-size: 64px;
  }
  @include large {
    font-size: 72px;
  }
  @include mh300 {
    height: 225px;
  }
  @include mh400 {
    height: 275px;
  }
  @include mh500 {
    height: 375px;
  }
  @include mh600 {
    height: 475px;
  }
  @include mh700 {
    height: 575px;
  }

}

.parallax-banner-payroll {
  background: url('../img/banner/pages/1.jpg') no-repeat 50% fixed;
  @include bg-cover;
}
.parallax-banner-hr {
  background: url('../img/banner/pages/2.jpg') 50% fixed;
  @include bg-cover;
}
.parallax-banner-insurance {
  background: url('../img/banner/insurance/8.jpg') 50% fixed;
  @include bg-cover;
}
.parallax-banner-resources {
  background: url('../img/banner/pages/4.jpg') no-repeat fixed right top rgba(0, 0, 0, 0);
  @include bg-cover;
}
.parallax-banner-company {
  background: url('../img/banner/pages/5.jpg') repeat fixed 50% center rgba(0, 0, 0, 0);
  @include bg-cover;
}

.banner-error {
  background:  url("/img/banner/home/10.jpg") repeat scroll 50% 50% rgba(0, 0, 0, 0);
  background-size: cover;
  max-height: 500px;
  @include bg-cover;
}

/* Insurance Site Banners */

.parallax-banner-insurance_home {
  //background: url("../img/banner/pages/a7.jpg") no-repeat scroll center top rgba(0, 0, 0, 0);
  background: url("../img/banner/insurance/14.jpg") repeat fixed top center transparent;
  @include bg-cover;
}

.parallax-banner-insurance_workers-comp {
  background: url("../img/banner/insurance/3.jpg") repeat fixed top center transparent;
  @include bg-cover;
}

.parallax-banner-insurance_business {
  background: url("../img/banner/insurance/7.jpg") repeat fixed top center transparent;
  @include bg-cover;
}

.parallax-banner-insurance_life {
  background: url("../img/banner/insurance/12.jpg") repeat fixed 50% center transparent;
  @include bg-cover;
}

.parallax-banner-insurance_medical {
  background: url("../img/banner/insurance/5.jpg") repeat fixed 50% center transparent;
  @include bg-cover;
}

.parallax-banner-insurance_retirement {
  background: url("../img/banner/insurance/17.jpg") repeat fixed right top transparent;
  @include bg-cover;
}

.parallax-banner-insurance_cyber {
  background: url("../img/banner/insurance/18.jpg") repeat fixed 50% center transparent;
  @include bg-cover;
}

.parallax-banner-insurance_auto {
  background: url("../img/banner/insurance/25.jpg") repeat fixed 50% center transparent;
  @include bg-cover;
}

.parallax-banner-insurance_home-renters {
  background: url("../img/banner/insurance/21.jpg") repeat fixed 50% center transparent;
  @include bg-cover;
}

/* Home and Renters Site Banners */

.parallax-banner-hris {
  background: url("../img/banner/insurance/21.jpg") repeat fixed 50% center transparent;
  @include bg-cover;
}



.banner .carousel .carousel-control {
  width: 10%;
}

.banner .item > img {
  min-height: 200px;
  //visibility: hidden;
}

.banner .slide-one {
  bottom: 0;
  color: #4a4a4a;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  height: 100%;
  width: 100%;
  .caption-centered {
    @extend %center-vertical;
    h2, p {
      margin: 0 0 12px 0;
      padding: 0 12px;
      text-shadow: 0 0 1px #000,
        2px 2px 1px #fff,
        2px -2px 1px #fff,
        -2px 2px 1px #fff,
        -2px -2px 1px #fff,
        1px 1px 2px #fff,
        0 0 15px #fff,
        1px 1px 2px #000,
        0 0 3px #000;
      @include small-tablet {
        margin: 0 0 16px 0;
        padding: 0 16px;
      }
      @include tablet {
        margin: 0 0 20px 0;
        padding: 0 20px;
      }
      @include desktop {
        margin: 0 0 24px 0;
        padding: 0 24px;
      }
      @include large {
        padding: 0 26px;
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 400;
      margin-top: 0;
      letter-spacing: -2px;
      color: $dark-green;
      @include small-tablet {
        font-size: 36px;
      }
      @include tablet {
        font-size: 48px;
      }
      @include desktop {
        font-size: 64px;
      }
      @include laptop {
        font-size: 76px;
      }
      @include large {
        font-size: 82px;
        line-height: 120px;
        word-spacing: 18px;
        margin-bottom: 0 !important;
      }
      @media screen and (min-width: 1600px) {
        font-size: 95px;
      }
    }
    p {
      font-weight: 100;
      line-height: 1.5em;
      word-spacing: 3px;
      color: #555;
      display: block;
      padding: 0 2em;
      @include small-tablet {
        font-size: 16px;
      }
      @include tablet {
        font-size: 22px;
      }
      @include desktop {
        font-size: 26px;
        margin: 20px 0 5px;
      }
      @include large {
        font-size: 28px;
      }
      @media screen and (min-width: 1600px) {
        font-size: 32px;
      }
    }
    .btn {
      margin: 0 auto;
      display: inline-block;
      font-size: 14px;
      padding: 6px 11px;
      @include tablet {
        font-size: 20px;
        padding: 13px 22px;
      }
    }
    .btn-default {
      color: $dark-green;
      background-color: #fff;
      text-shadow: 0 0 0;
    }
  }
}



.parallax-banner-company .slide-one ,
.parallax-banner-resources .slide-one ,
.parallax-banner-payroll .slide-one ,
.parallax-banner-insurance .slide-one ,
.parallax-banner-hr .slide-one ,
.parallax-banner-insurance_home .slide-one ,
.parallax-banner-insurance_workers-comp .slide-one ,
.parallax-banner-insurance_business .slide-one ,
.parallax-banner-insurance_life .slide-one ,
.parallax-banner-insurance_medical .slide-one,
.parallax-banner-insurance_retirement .slide-one ,
.parallax-banner-insurance .slide-one,
.parallax-banner-insurance_auto .slide-one,
.parallax-banner-insurance_cyber .slide-one,
.parallax-banner-insurance_home-renters .slide-one,
.parallax-banner-hris .slide-one {
  background: rgba(0, 0, 0, 0) radial-gradient(ellipse at center center , rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 12%, rgba(255, 255, 255, 0.75) 37%, rgba(255, 255, 255, 0.5) 46%, rgba(255, 255, 255, 0) 70%) repeat scroll 0 0;
}




/* hix insurance banner text overrides */
.site-npis .banner .parallax-banner-insurance_retirement .slide-one .caption-centered > p {
  color: #4a4a4a;
}

.page-home .banner .slide-one h2 ,
.page-home .banner .slide-one p {
  text-shadow: 0 0 0 rgba(0,0,0,0) !important;
}

.page-home .banner .slide-one .btn-default{
  margin-top: 20px;
}



// portal bar for home page

.portal-bar {
  background: linear-gradient(to right, #eee 50%, #ddd 50%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  nav {
    margin: 0;
    span {
      display: inline-block;
      float: left;
      padding: 1em 0;
      text-align: center;
      width: 50%;
    }
  }
}




// about

.wrapper > .feature > .container > div[class*="-content"] > .row  > div:last-of-type h4 {
  margin-top: 0;
}


.about-content > .row > div {
  min-width: 100%;
  @include desktop {
    max-width: 50%;
    min-width: 50%;
  }
  @include large {
    max-width: 100%;
    min-width: 0%;
  }
}


.about-content img {
  margin: 0 0 40px;
  max-width: 100%;
  @include desktop {
    margin: 0 auto 20px;
  }
}


.about-content h4 {
  font-family: "Open Sans",sans-serif;
  font-weight: 300;
  @include desktop {
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 22px;
    line-height: 24px;
  }
  @include large {
    font-size: 24px;
    line-height: 34px;
  }
}


.about-content p {
  font-size: 14px;
  line-height: 22px;
  margin: 0.9em 0 0 0;
  padding: 0;
  @include desktop {
    font-size: 15px;
    line-height: 24px;
    margin: 0.5em 0 0;
  }
  @media screen and (min-width: 1000px) {
    font-size: 13px;
    line-height: 19px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 15px;
    line-height: 26px;
  }
}




// triple swoosh

.triple-swoosh-content {
  margin: 0 5%;
  width: 90%;
  @include desktop {
    margin: 0;
    width: 100%;
  }
}

.triple-swoosh-content .row > div:last-of-type {
  text-align: center;
}

.triple-swoosh-content .row > div:last-of-type img {
  display: inline-block;
  margin: 3em 0 0;
  max-width: 375px;
}


// Legal Expertise

#legal-expertise .legal-expertise-content > .row {
  & > div:first-of-type {
    h4 {
      margin-top: 0;
    }
  }
  & > div:last-of-type {
    h4 {
      margin: 0 0 0.75em;
    }
    ul {
      padding: 0 0 0 2em;
      font-size: 0.9em;
    }
    li {
      margin: 0.75em 0;
      list-style-type: circle;
    }
    img {
      width: auto;
      margin: 2em auto 0;
    }
    p:last-of-type {
      font-size: 0.9em;
      vertical-align: middle;
      margin: 2em 1em;
      line-height: 1.75em;
      font-style: italic;
    }
  }
}


// insurance page

.logos-wc > ul {
  overflow: hidden;
  padding: 0;
  position: relative;
  margin: 2em 0 0 5%;
  width: 90%;
  @include large {
    width: 100%;
    margin: 0;
  }
}

.logos-insurance img {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 90px;
  height: auto;
  max-width: 80%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

// workers comp

.logos-wc ul li {
  list-style-type: none;
  position: relative;
  height: 110px;
  text-align: center;
}

.logos-wc > ul > li[class*="col-sm-"] {
  display: inline-block;
  width: 32%;
  @include small-tablet {
    height: 100px;
    display: inline-block;
    width: 19%;
  }
  @include tablet {
    height: 150px;
  }
  @include desktop {
    height: 175px;
    width: 20%;
  }
}

.logos-insurance.logos-wc > ul > .col-sm-3 {
  @include desktop {
    width: 25%;
  }
}

.panels-blocks ul {
  font: 100 1.1em "PT Sans",sans-serif;
  list-style: outside none none;
  margin: 0 5%;
  overflow: hidden;
  padding: 0;
  width: 90%;
  @include large {
    padding: 0;
    width: 100%;
  }
}

.panels-blocks > ul > li {
  display: block;
  font-size: 13px;
  padding: 0;
  min-height: 75px;
  @include phone {
    display: inline-block;
    max-width: 49%;
  }
  @include tablet {
    max-width: 100%;
  }
}

.panels-blocks.row > ul > li > span::before {
  content: url("/img/icons/list_checkbox.png");
  display: inline-block;
  margin-left: 1em;
  vertical-align: middle;
}

.panels-blocks li span {
  display: inline-block;
  margin: 2em 0 0;
  vertical-align: middle;
  @include desktop {
    margin: 1.5em 0 0.4em;
  }
}

// Colored Boxes Single Row

/*
.panels-blocks {
    ul {
      list-style: outside none none;
      margin: 10px 0;
      padding: 0;
      width: 100%;
    }
  span {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: rgba(0, 0, 0, 0.07);
    border-color: rgba(0, 0, 0, 0.2);
    border-image: none;
    border-style: double none;
    border-width: 1px 0;
    color: #eee;
    font: 800 1.4em/1.4em "Pathway Gothic One";
    height: 1.6em;
    padding: 5px 0;
    letter-spacing: -1px;
    text-shadow: 1px 1px 0 #222;
    @include box-centered;
  }

  li {
    background-attachment: scroll;
    background-color: #556270;
    background-image: url("/nu/inc/bgAssets/diagonal-noise.png");
    background-origin: padding-box;
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto auto;
    border: 1.5px outset rgba(0, 0, 0, 0.5);
    margin: 2% 0 0 2%;
    min-height: 150px;
    width: 23%;
  }

  & .col-sm-2 {
    width: 14.5%;
  }

  ul > li:nth-of-type(1) {
    background-color: get-color(myColors, 0);
  }

  ul > li:nth-of-type(2) {
    background-color: get-color(myColors, 1);
  }

  ul > li:nth-of-type(3) {
    background-color: get-color(myColors, 2);
  }

  ul > li:nth-of-type(4) {
    background-color: get-color(myColors, 3);
  }

  ul > li:nth-of-type(5) {
    background-color: get-color(myColors, 4);
  }

  ul > li:nth-of-type(6) {
    background-color: get-color(myColors, 5);
  }

  ul > li:nth-of-type(7) {
    background-color: get-color(myColors, 6);
  }

  ul > li:nth-of-type(8) {
    background-color: get-color(myColors, 7);
  }

  ul > li:nth-of-type(9) {
    background-color: get-color(myColors, 8);
  }

  ul > li:nth-of-type(10) {
    background-color: get-color(myColors, 9);
  }

  ul > li:nth-of-type(11) {
    background-color: get-color(myColors, 10);
  }

  ul > li:nth-of-type(12) {
    background-color: get-color(myColors, 11);
  }

  ul > li:nth-of-type(13) {
    background-color: get-color(myColors, 12);
  }

  ul > li:nth-of-type(14) {
    background-color: get-color(myColors, 13);
  }

  ul > li:nth-of-type(15) {
    background-color: get-color(myColors, 14);
  }

  ul > li:nth-of-type(16) {
    background-color: get-color(myColors, 15);
  }

  & > .three.columns:first-of-type {
    & + .three.columns + .three.columns + .three.columns + .three.columns,
    & + .three.columns + .three.columns + .three.columns + .three.columns + .three.columns + .three.columns + .three.columns + .three.columns {
      margin-left: 0;
    }
  }
}
*/

// Prudential

.prudential-list {
  border-bottom: 2px solid #ddd;
  border-top: 2px solid #ddd;
  display: table;
  list-style: outside none none;
  margin: 6em 0 3em;
  padding: 0;
  text-align: center;
  width: 100%;
}
.prudential-list > li {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    width: 100%;
    display: block;
    padding: 0.6em;
}
@media screen and (min-width: 800px) {
  .prudential-list > li {
    border-bottom: 0 none;
    width: 19%;
    display: table-cell;
  }
}
.v-wrap > img {
    display: block;
    height: auto;
    margin: 2em auto 3em;
    width: 100%;
    padding: 0;
}
@media screen and (min-width: 800px) {
  .v-wrap > img {
    display: inline-block;
    margin: 0 auto;
    width: 90%;
  }
}
.v-wrap > span {
    display: block;
    font: 400 16px/32px "PT Sans",sans-serif;
}
.prudential-content > .row > div {
    display: inline-block;
    float: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
    @include tablet {
      width: 32.9%;
      & div:nth-of-type(1) {
        text-align: left;
      }
    }
}
.prudential-content > .row > div::before {
    content: "";
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 1%;
}
.v-wrap {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}
.prudential-content address {
    margin: 0;
    padding: 0;
    position: relative;
}


// resources page

#career-opportunities .container .row .btn-default {
  margin: 2em 0 1em;
}



// FORMS

// honeypot

#form_message {
  display: none;
}

// captcha

.npcap > div > div,
.npcap > div > div > iframe {
  width: 100%;
  @include tablet {
    width: calc();
  }
}

.rc-anchor {
  max-width: 95%;
  @include small-tablet {
    max-width: 100%;
  }
}

#formFly .form-submit .btn-theme {
  margin-top: 6px;
}

#contact-our-company .npcap {
  display: inline-block;
  float: left;
}

#contact-our-company .form-message {
  max-width: 100%;
  padding-left: 52%;
  text-align: right;
}

.form-group {
    margin-bottom: 19px;
}

.form-submit {
  text-align: right;
}

// text area

#contact_message {
  height: 170px;
  margin-bottom: 10px;
}


.np-form .error {
  color: red;
  font-style: italic;
}


.np-form .npcap {
  background-clip: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 10px 0;
  height: 82px;
  width: 309px;
  padding: 2px;
  position: relative;
  // the following is necessary to prevent the recap from breaking layout with its width
  max-width: 100%;
  overflow: hidden;
}

.gcap {
  position: relative;
  z-index: 2;
}

.npcap > a {
  cursor: pointer;
  display: inline-block;
  left: 0;
  line-height: 1.5em;
  min-height: 2em;
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.npcap > input[name="form_message"] {
  display: none;
}

// privacy page

.privacy-content .row {
  display: inline-block;
  margin: 0 10%;
  width: 80%;
  min-width: 80%;
  @include tablet {
    margin: 0 5%;
    width: 90%;
    min-width: 90%;
  }
}

// privacy blocks
.txtPage > div {
  margin: 30px 0;
  @include desktop {
    display: inline-block;
    margin: 0 4% 0 0;
    vertical-align: top;
    width: 45%;
  }
}

// privacy section headings
.txtPage > div > a:first-of-type {
  border-bottom: 1px solid;
  display: block;
  font-size: 18px;
  margin: 2em 0 0.75em;
}



// to top button hover

.totop a,
.totop a:visited {
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 30px;
  min-height: 50px;
  line-height: 25px;
  padding-top: 8px;
  text-align: center;
  width: 50px;
}

.totop a:hover {
  color: #fff;
  text-shadow: 1px 1px 0 #333;
  padding-top: 5px;
}



// overlay of content

#content_overlay {
  display: none;
  background-color: rgba(0, 6, 0, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 50;
}




// flyout form

#formFly {
  background: none repeat scroll 0 0 #fff;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  bottom: 60px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  height: auto;
  left: -80%;
  position: fixed;
  top: 65px;
  width: 80%;
  z-index: 500;
  @include small-tablet {
    top: 125px;
    left: -360px;
    width: 360px;
  }
  button {
    border-color: #CCC;
  }
}

#formFly .np-form {
  height: 100%;
  overflow: auto;
  padding: 0 2%;
  position: absolute;
  width: 100%;
}

#formFly .row {
  margin: 0;
  padding: 13px 10px;
}

.form-group > label {
  display: none;
}


#formFly .row > .form-job:first-of-type {
  border-bottom: 3px solid green !important;
}


// headings
.form-text > span {
  border-bottom: 1px solid #ccc;
  margin: 25px 0 11px;
  padding-bottom: 4px;
  display: block;
}

.form-text > .txt_title {
  border-bottom: 0 none;
  font-size: 20px;
  line-height: 20px;
  padding-bottom: 0;
}

/* Home and Renters Override */

.site-npis .form-text > .txt_title ,
.site-haris .form-text > .txt_title {
  font-size: 17px;
}

#formFly .row > .form-text:first-of-type > span {
  margin-top: 0;
}

// overrides for default form styling

#formFly .form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555;
  display: block;
  font-size: 12px;
  height: 28px;
  line-height: 1.42857;
  padding: 4px 8px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

#formFly input,
#formFly input:focus,
#formFly textarea,
#formFly textarea:focus {
  font-size: 13px !important;
}

#formFly .form-group {
  margin-bottom: 10px;
}

// limt sizing on the textarea

#formFly [name="contact_message"] {
  height: 100px;
  max-width: 300px;
  min-width: 100%;
  margin-top: 5px;
}

#formFly .row > .discussion-topics > .form-check,
#formFly .row > .topic-options > .hr > .form-hr,
#formFly .row > .topic-options > .support > .form-support,
#formFly .row > .topic-options > .careers > .form-job,
#formFly .row > .topic-options > .insurance > .form-ins {
  display: inline-block;
  margin: 0 0 1%;
  text-align: left !important;
  vertical-align: middle;
  width: 100%;
  @include small-tablet {
    width: 32%;
  }

  button {
    font-size: 10px;
    padding: 3px 0;
    vertical-align: top;
    width: 100%;
  }
}

#formFly .style-btn {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 #fff;
  border-bottom-right-radius: 3px;
  border-color: #ccc #ccc #ccc -moz-use-text-color;
  border-image: none;
  border-style: solid solid solid none;
  border-top-right-radius: 3px;
  border-width: 1px 1px 1px 0;
  //color: #565656;
  color: $dark-green;
  cursor: pointer;
  font-size: 25px;
  height: 40px;
  left: 100%;
  line-height: 32px;
  margin-left: 0;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 40px;
  z-index: 501;
  &:hover {
    color: $green;
  }
  & > i {
    line-height: 35px;
    padding-right: 2px;
  }
}

// job buttons


#formFly .row > .form-job,
#formFly .row > .form-ins {
    width: 49%;
}

#formFly .row > .form-job > *,
#formFly .row > .form-ins > * {
    width: 100%;
}


// Sitemap Page

.sitemapDisplay {
  padding: 0;
}

.sitemapDisplay * {
  list-style: outside none none;
}

.sitemap h2 {
  margin-bottom: 1em;
  text-align: center;
}

.sitemapDisplay .sitemapSub {
  padding: 0;
}

.sitemapyDisplay .dropdown-menu,
.sitemapyDisplay .sitemapPages {
  box-shadow: 0 0 0;
  float: none;
  display: inline-block;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 0;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  font-size: 14px;
  left: 0;
  list-style: outside none none;
  margin: 2px 0 4em;
  min-width: 160px;
  text-align: left;
}

.sitemapDisplay .dropdown-menu {
  display: block;
  box-shadow: 0 0 0;
  float: none;
  border: 0 none rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  position: relative;
  border-radius: 0;
  z-index: 0;
}

.sitemapDisplay .sitemapTitle {
  width: 100%;
  text-align: center;
}

.sitemapDisplay .sitemapTitle a img {
  display: none;
}

.sitemapDisplay .sitemapHeading,
.sitemapPages .dropdown-toggle,
.sitemapPages > li > .page-scroll {
  border-bottom: 3px double #cccccc;
  display: inline-block;
  font-size: 1.4em;
  padding: 4px 10px;
  width: 100%;
  &::after {
    content: url("../img/icons/external2.png");
    float: right;
  }
}


.sitemapSub > li:nth-of-type(2n) ,
.sitemapDisplay .sitemapPages > li > ul > li:nth-of-type(2n) {
    background-color: rgba(0, 0, 0, 0.06);
}

.sitemapDisplay .sitemapSub > li ,
.sitemapDisplay .sitemapPages > li > ul > li {
    color: #acacac;
    display: list-item;
    list-style: inside none square;
    padding: 0.6em 0 0.6em 0.8em;
}

.sitemapPages li a {
  display: inline-block;
}

.sitemapSection ,
.sitemapPages > li {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    margin: 0 0 4em;
    border-radius: 0;
}

.sitemapPages {
  padding: 0;
}

.sitemapSection li a,
.sitemapPages li li a {
  color: $dark-green;
  display: inline-block;
  padding-left: 6px;
}


.sitemapPages li li a:hover {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    color: $green;
}
















// waypoints


[class*="animation"] {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

[class*="animated"] {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}


@include delay-x-list;

@include duration-x-list;








/*
.feature .feature-item {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation-duration: 1.25s;
    -moz-animation-duration: 1.25s;
    -ms-animation-duration: 1.25s;
    -o-animation-duration: 1.25s;
    animation-duration: 1.25s;
}

.feature .feature-item.animation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}


.feature .feature-item a i {
    display: inline-block;
    width: 110px;
    height: 110px;
    line-height: 102px;
    font-size: 40px;
    color: #ddd;
    border: 5px solid #ddd;
    border-radius: 150px;
    box-shadow: inset 0 0 1px 1px #fff, 0 0 1px 4px #fff;
    -webkit-transition: all 0.35s linear;
    -moz-transition: all 0.35s linear;
    -ms-transition: all 0.35s linear;
    -o-transition: all 0.35s linear;
    transition: all 0.35s linear;
}

*/





/* this section is for the get quote button */

.getQuoteButton {
  display: none;
  @include tablet {
    border-radius: 2px;
    margin: 25px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 5;
  }
}

.getQuoteButton span, .getQuoteButton i {
  display: inline-block;
}

.getQuoteButton > a > i {
  padding-left: 6px;
}





/*  Error Page */
.error-page p {
  font-size: 16px;
}





/*  INSURANCE SITE */

/*  hide heading icons on insurance website */

.site-npis .navbar-default .navbar-nav li a {
  @include tablet {
    font-size: 9px;
    padding: 30px 6px;
  }
  @media screen and (min-width: 900px) {
    font-size: 10px;
  }
  @include desktop {
    font-size: 11px;
    padding: 30px 10px;
  }
  @include laptop {
    font-size: 13px;
    padding: 30px 10px;
  }
  @include large {
    font-size: 14px;
    padding: 30px 16px;
  }
}


.site-npis .navbar-default .navbar-nav > li {
  text-align: left;
  @media screen and (min-width: 900px) {
    min-width: 50px;
    text-align: center;
  }
  @include desktop {
    min-width: 60px;
  }
  @include laptop {
    min-width: 75px;
  }
  a {
    margin-bottom: -1px;
  }
}


.site-npis.page-home .banner .slide-one h2 {
  @include desktop {
    font-size: 48px;
  }
  @include large {
    font-size: 74px;
  }
}

.site-npis .container .default-header i {
  display: none;
}

.site-npis .contact {
  background-color: #FAFAFA;
}



.site-npis.page-home .portfolio-content .p-element {
  width: 19.97%;
}

.site-npis .portfolio #filters {
  margin-bottom: 75px;
}




.about-content {
  max-width: 100%;
}



/* Resources page */

#netpay-reports-viewer .about-content .row div img {
  margin: 40px 0 0;
  @include desktop {
    margin: 0;
  }
}



/* Calculator Button on Resources page */
.calc-button {
  display: none;
}
.parallax-banner-resources .calc-button {
  background-color: #fff;
  border-radius: 3px;
  padding: 0.5em 1em;
  position: absolute;
  right: 20px;
  top: 20px;
  @include tablet {
    display: block;
  }
  i {
    border-left: 1px solid #ccc;
    color: #59ae26;
    margin-left: 8px;
    padding-left: 8px;
  }
}
