/**
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
 * VARIABLES
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

/* Basic Colours */

$white        : #ffffff;
$default-text   : #9a9a9a;
$black        : #555555;
$dark-black     : #333333;
$grey       : #cccccc;
$dark-grey      : #aaaaaa;
$light-grey     : #eaeaea;
$red        : #e63131;
$dark-red     : #c30f0f;
$green        : #59ae26;
$dark-green     : #3f910d;
$lblue        : #1ec4d8;
$dark-lblue     : #07a9bc;
$blue       : #0275d7;
$dark-blue      : #025ead;
$brown        : #c27256;
$dark-brown     : #98462a;
$orange       : #ed450c;
$dark-orange    : #be3203;
$purple       : #852b9c;
$dark-purple    : #6a0883;
$pink       : #fb226f;
$dark-pink      : #d00a50;
$yellow       : #ffd800;
$dark-yellow    : #dcbb03;

/* Socail Colors */

$linkedin: #0177B5;
$yelp: #C41200;
$twitter: #5EA9DD;
$facebook: #3B5998;

/* Color for this Theme */

$theme-color    : $green;
$theme-link     : $green;
$theme-hlink    : $dark-green;

/* Media Queries */

$phone-width: 400px;
$small-tablet-width: 500px;
$tablet-width: 767px;
$desktop-width: 1024px;
$laptop-width: 1200px;
$large-width: 1400px;


// old golden ratio func

$numberArrays: (
  goldenRatio: (
    0: 0,
    1: 1.618,
    2: 2.618,
    3: 4.236,
    4: 6.854,
    5: 11.089,
    6: 17.942,
    7: 29.030
  )
);

@function get-number($arrayKey, $value: 0) {
  @return map-get(map-get($numberArrays, $arrayKey), $value);
}

// max-width: get-number(goldenRatio, 2);


// OLD COLORS

$color-green: #436410;
$color-green-light: #66A678;
$color-green-dark: #000;
$color-gray-light: #EEEEEE;
$color-gray-dark: #AEAEAE;

$color-highlight-yellow: #f9f861;
$color-highlight-blue: #000;

$color-sm-linkedin: #0177B5;
$color-sm-yelp: #C41200;
$color-sm-twitter: #5EA9DD;
$color-sm-facebook: #3B5998;

// Backgrounds

$bg_color1: rgba(0, 0, 0, 0.12);
$bg_color2: rgba(0, 50, 0, 0.3);
$bg_color3: rgba(0, 0, 200, 0.2);
$bg_color4: rgba(0, 0, 200, 0.8);
$bg_color5: rgba(10, 150, 0, 0.15);
$bg_color6: rgba(150, 150, 0, 0.15);
$bg_color7: rgba(150, 150, 250, 0.15);
$bg_color8: rgba(150, 150, 0, 0.15);
$bg_color9: rgba(150, 10, 0, 0.15);
$bg_color10: rgba(50, 150, 0, 0.15);


// Palates

// http://www.colorcombos.com/getthelink.html?color0=CCCCCC&color1=70A370&color2=E3ECEC&color3=99B66E&color4=B2C99E&color5=41684C&color6=7EAC8B&color7=A7C5B0&color8=2C4D35&color9=3A754B


$palates: (
  myColors: (
    0: #81574A,
    1: #9E8668,
    2: #C2C2A5,
    3: #D9E4BE,
    4: #A3BE9D,
    5: #E9E7CD,
    6: #C7B09A,
    7: #E4B096,
    8: #2C4D35,
    9: #3A754B,
    10: #CCCCCC,
    11: #1b85b8,
    12: #5a5255,
    13: #559e83,
    14: #ae5a41,
    15: #c3cb71,
  ),
  bgColors: (
    0: #CCCCCC,
    1: #70A370,
    2: #E3ECEC,
    3: #99B66E,
    4: #B2C99E,
    5: #41684C,
    6: #7EAC8B,
    7: #A7C5B0,
    8: #2C4D35,
    9: #3A754B,
  ),
  greens: (
    0: #C0EA89,
    1: #8CAA64,
    2: #4D5E37,
    3: #576B3E,
    4: #384428,
  ),
  corpId: (
    0: #108DCC,
    1: #CC9010,
    2: #EDA41E,
    3: #FEC608,
    4: #D7ED11,
  ),
  vintageModern: (
    0: #8C2318,
    1: #5E8C6A,
    2: #88A65E,
    3: #BFB35A,
    4: #F2C45A,
  ),
  brightBoldCorp: (
    0: #FF8C33,
    1: #FFB433,
    2: #99CEFF,
    3: #0087FF,
    4: #3339FF,
  ),
  peaceCorps: (
    0: #81574A,
    1: #9E8668,
    2: #C2C2A5,
    3: #D9E4BE,
    4: #A3BE9D,
  ),
  sabonDeCorps: (
    0: #40B5E4,
    1: #CBE6E9,
    2: #E9E7CD,
    3: #C7B09A,
    4: #E4B096,
  ),
  skyCorps2: (
    0: #9FFEFE,
    1: #FFFEA7,
    2: #FFB95E,
    3: #B6D8B1,
    4: #003272,
  ),
  maddeningCaravan: (
    0: #FAD089,
    1: #FF9C5B,
    2: #F5634A,
    3: #ED303C,
    4: #3B8183,
  ),
  metroStyle: (
    0: #00aedb,
    1: #a200ff,
    2: #f47835,
    3: #d41243,
    4: #8ec127,
  ),
  pastelColors: (
    0: #1b85b8,
    1: #5a5255,
    2: #559e83,
    3: #ae5a41,
    4: #c3cb71,
  ),
  blues: (
    0: #011f4b,
    1: #03396c,
    2: #005b96,
    3: #6497b1,
    4: #b3cde0,
  ),
  summertime: (
    0: #e8d174,
    1: #e39e54,
    2: #d64d4d,
    3: #4d7358,
    4: #9ed670,
    5: #63ace5,
  ),
  cheerUpEmoKid: (
    0: #556270,
    1: #4ECDC4,
    2: #C7F464,
    3: #FF6B6B,
    4: #C44D58,
  )
);

@function get-color($color, $value: 0) {
  @return map-get(map-get($palates, $color), $value);
}