/* Less Style sheet */

/* Colors */

.black {color: $black;}
.red {color: $red;}
.green {color: $green;}
.lblue {color: $lblue;}
.blue {color: $blue;}
.brown {color: $brown;}
.orange {color: $orange;}
.purple {color: $purple;}
.pink {color: $pink;}
.yellow {color: $yellow;}

/* Background */

.br-black {background: $black;}
.br-red {background: $red;}
.br-green {background: $green;}
.br-lblue {background: $lblue;}
.br-blue {background: $blue;}
.br-brown {background: $brown;}
.br-orange {background: $orange;}
.br-purple {background: $purple;}
.br-pink {background: $pink;}
.br-yellow {background: $yellow;}

/* General CSS */

a {
	color: $theme-link;
}

a:hover {
	color: $theme-hlink;
}

a:hover,a:active,a:focus {
	color: $theme-hlink;
}

.btn-theme {
	background: $theme-link;
	color: #fff !important;
	&:hover {
		background: $theme-hlink;
	}
}

/* Block Default Header */

.container {
	.default-header {
		i {
			color: $theme-color;
		}
	}
}

/* Main Header */

.header-info {
	.social {
		a {
			color: $theme-color;
			&:hover i {
				color: $theme-color;
			}
		}
	}
}

/* Navigation Bar */

.navbar-default {
	.navbar-nav {
		li {
			a {
				&:hover {
					color: $theme-link;
				}
				&:active {
					color: $theme-link;
				}
				&:focus {
					color: $theme-link;
				}
			}
		}
		.dropdown {
			a {
				&:hover, &:focus, &:active {
					.caret {
						color: $theme-hlink
					}
				}
			}
			.dropdown-menu {
				background: $theme-color;
				&:after {
					border-bottom-color: $theme-color !important;
				}
				li {
					a {
						color: $theme-link;
						border-bottom: 1px solid $theme-link;
						&:hover {
							background: $theme-color;
							color: $white;
						}
					}
				}
			}
		}
	}
	.nav {
		.open {
			a {
				color: $theme-link;
				&:hover, &:active, &:focus {
					color: $theme-color;
				}
			}
		}
	}
	.navbar-toggle {
		border-color: $theme-color;
		.icon-bar {
			background: $theme-color;
		}
	}
}

/* banner */

.banner {
	.carousel {
		.carousel-control {
			span {
				color: $theme-color;
				&:hover {
					color: $theme-hlink;
				}
			}
		}
	}
}

/* Features */

.feature {
	.feature-item:hover {
		a i {
			color: $theme-color;
			box-shadow: inset 0 0 1px 1px $theme-color, 0 0 1px 4px $theme-color;
		}
	}
}

/* Service */

.service {
	.service-item {
		&:hover a i {
			color: $theme-color;
		}
	}
}


/* Pricing */

.pricing {
	.pricing-item.highlight h3 {
		background: $theme-color;
	}
}

/* Portfolio */

.portfolio-content .p-hover i {background: $theme-color;}

/* video */

.video .video-caption i {background: $theme-color;}


/* Testimonial */

.testimonial {
	.carousel .carousel-control {color: $theme-color;}
	.testimonial-content {
		p {
			&:before {color: $theme-color;}
			&:after {color: $theme-color;}
		}
	}
}

/* About Us */

.about {
	.about-content {
		h3 {
			color: $theme-color;
		}
	}
	.member-content {
		.member-item {
			.member-hover-color {
				background: $theme-color;
			}
			.social a {
				border: 1px solid $theme-hlink;
			}
		}
	}
}

@media (max-width: 767px){

	.member-hover {
		background: $theme-color;
	}

}

/* Process */

.process {
	.process-content {
		.process-item {
			&:hover {
				i {
					color: $theme-color;
				}
			}
			border-bottom: 5px solid $theme-color;
		}
	}
}


/* Counter */

.counter {
	.counter-item {
		&:hover i {
			color: $theme-color;
		}
	}
}


/* Blog Page */

.blog {
	.entry {
		.entry-head {
			.date {
				background: $theme-color;
			}
			.meta {
				background: $theme-color;
				&:after {
				border-bottom-color: $theme-color;
				}
			}
		}
	}
}

/* Contact */

.contact {
	.contact-form {
		form {
			input {
				color: $theme-color;
			}
			textarea {
				color: $theme-color;
			}
		}
	}
}

/* sign area */

.sign-area {
	.nav-tabs.nav-justified>.active>a,
	.nav-tabs.nav-justified>.active>a:hover,
	.nav-tabs.nav-justified>.active>a:focus {
		background: $theme-hlink;
		color: #fff;
		border-color: $theme-hlink;
		border-bottom-color: transparent;
	}
	.nav-tabs.nav-justified>li>a {
		border-color: $theme-color;
	}
	.nav-tabs.nav-justified>li>a:hover {
		background: $theme-color;
		color: #fff;
		border-color: $theme-color;
	}
	.tab-content {border: 1px solid $theme-color;border-top:0;}
}

@media (max-width: 767px){
	.sign-area {
		.tab-content {
			border-top: 1px solid $theme-color;
		}
	}

}

/* Footer */

.footer {
	background: $theme-color;
	border-top: 1px solid $theme-hlink;
	border-bottom: 5px solid $theme-hlink;
	.footer-content {
		border-bottom: 1px solid $theme-hlink;
		.footer-widget {
			h4 {
				border-bottom: 1px solid $theme-hlink;
			}
		}
	}
	.social {
		a:hover {
			color: $theme-color;
		}
	}
}

/* To Top */

.totop {
	a {
		background: $theme-hlink;
		&:visited {
			background: $theme-hlink;
		}
	}
}
